import { LockClosedIcon, UserIcon } from "@heroicons/react/24/solid";
import { LandData } from "apis/fetchMapData";
import GradientCard from "components/cards/Gradient";
import { twMerge } from "tailwind-merge";
import { useAccount } from "wagmi";

export type LandSize = 0 | 1 | 2 | 3;
export const landSizes = [
  ["S", "bg-gradient-to-br from-primary/30 to-neutral2 text-contrast", "1x1"],
  ["M", "bg-gradient-to-br from-primary to-neutral  text-contrast", "2x2"],
  ["L", "bg-gradient-to-br from-contrast2 to-primary text-contrast", "5x5"],
  ["XL", "bg-gradient-to-br from-contrast2 to-primary text-contrast", "10x10"],
] as const;

type Props = {
  setSelectedLand: React.Dispatch<React.SetStateAction<LandData | undefined>>;
  selectedLand: LandData | undefined;
  mapData: LandData[];
};
export const MtbMapLands: React.FC<Props> = ({
  setSelectedLand,
  selectedLand,
  mapData,
}) => {
  const { address } = useAccount();
  return (
    <div className="h-auto pb-3 grow">
      {mapData.map((land) => {
        const isLocked = land.status === "locked";
        const isOwned = !!land.owner;
        const isOwner = isOwned && land.owner === address;
        return (
          <GradientCard
            onClick={() =>
              setSelectedLand((prev) =>
                prev?.id === land.id ? undefined : land
              )
            }
            key={land.id}
            className={twMerge(
              "relative ml-2 inline-block h-20 w-20",
              isOwner && "bg-gradient-to-tr from-primary to-secondary",
              isLocked && "bg-gradient-to-tr from-primary to-contrast2",
              selectedLand?.id === land.id && "p-2"
            )}
            bgClassName="bg-neutral2"
          >
            {isLocked && (
              <LockClosedIcon className="absolute z-50 h-4 rounded-full top-3 right-3 text-contrast/50 " />
            )}
            {isOwned && (
              <UserIcon className="absolute z-50 h-4 rounded-full top-3 right-3 text-contrast/50 " />
            )}
            <div
              className={twMerge(
                "flex h-full w-full items-center justify-center text-2xl font-bold",
                "cursor-pointer select-none transition-all",
                landSizes[land.size][1],
                (isOwned || isLocked) && "text-contrast2",
                isOwner &&
                  "bg-gradient-to-r from-secondary to-primary text-contrast"
              )}
            >
              {landSizes[land.size][0]}
            </div>
          </GradientCard>
        );
      })}
    </div>
  );
};

import allowanceAbi from "./allowanceAbi.json";
import { readContract } from "wagmi/actions";

export const getAllowance = async (
  token: `0x${string}`,
  owner: `0x${string}`,
  spender: `0x${string}`
) => {
  const res = await readContract({
    address: token,
    abi: allowanceAbi,
    functionName: "allowance",
    args: [owner, spender],
  });

  console.log({allowance:res});
  return Number(res);
};

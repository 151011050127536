import { currencyContract } from "components/cards/MtbMap/utils/constants";
import { currentEnv } from "env";
import { useAccount, useBalance } from "wagmi";

export const useTokensBalance = () => {
  const { address } = useAccount();
  const { data: usdc } = useBalance({
    address,
    token: currencyContract[currentEnv]["usdc"],
  });
  const { data: mrkey } = useBalance({
    address,
    token: currencyContract[currentEnv]["mrkey"],
  });
  const { data: usdt } = useBalance({
    address,
    token: currencyContract[currentEnv]["usdt"],
  });
  const { data: busd } = useBalance({
    address,
    token: currencyContract[currentEnv]["busd"],
  });

  return { usdc, usdt, busd, mrkey };
};

export default useTokensBalance;
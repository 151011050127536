import { LandData } from "apis/fetchMapData";
import { MtbMap } from "components/LeafletMap/LeafletMap";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import mapGeo from "assets/map/map_geo.json";

export const useUrlHandler = (
  setMap: (m?: MtbMap) => void,
  setLand: (l?: LandData) => void,
  setLands: (l: LandData[]) => void,
  getLands: (dId: number, mId: number) => Promise<LandData[]>,
  district?: number,
  map?: number,
  land?: number
) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const stringSearchParams = searchParams.toString();

  const stringParams = ["district", "map", "land"] as const;
  const [districtParam, mapParam, landParam] = stringParams.map((p) => {
    const value = searchParams.get(p);
    return value ? Number(value) : undefined;
  });
  const updateSelection = () => {
    if (districtParam === undefined) {
      setMap(undefined);
      return setIsLoading(false);
    }
    if (mapParam === undefined) {
      setMap(undefined);
      return setIsLoading(false);
    }
    setIsLoading(true);
    getLands(districtParam, mapParam).then((landsData) => {
      setMap(
        mapGeo.features.find(({ properties }) => properties.id === mapParam)
          ?.properties
      );
      setLands(landsData);
      if (landParam === undefined) {
        setLand(undefined);
        return setIsLoading(false);
      }
      setLand(landsData.find(({ id }) => landParam === id));
      setIsLoading(false);
    });
  };
  useEffect(() => {
    if (
      "" + districtParam + mapParam + landParam !==
      "" + district + map + land
    ) {
      updateSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringSearchParams]);

  useEffect(() => {
    const stringParams = ["district", "map", "land"] as const;
    const objParams = { district, map, land };
    setSearchParams((prev) => {
      stringParams.forEach((p) => prev.delete(p));
      for (const p of stringParams) {
        if (objParams[p] === undefined) break;
        prev.set(p, objParams[p]!.toString());
      }
      return prev;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [district, map, land]);

  useEffect(() => {
    updateSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isLoading;
};

export default useUrlHandler;

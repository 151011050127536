import {
  prepareWriteContract,
  readContract,
  signTypedData,
  writeContract,
} from "wagmi/actions";
import { Chain } from "wagmi";
import noncePermitAbi from "./noncePermitAbi.json";
import { ethers } from "ethers";
import { currentEnv } from "env";
// import { signatureTest } from "components/cards/MtbMap/signatureTest";

export const domainNameMap: {
  dev: Record<"usdc" | "mrkey", string>;
  prod: Record<"usdc" | "mrkey", string>;
} = {
  dev: {
    usdc: "USD Coin (PoS)",
    mrkey: "MTBCOIN",
  },
  prod: {
    usdc: "USD Coin (PoS)",
    mrkey: "MTBCOIN",
  },
};
type Params = {
  /** Token contract address */
  currency: "usdc" | "mrkey";
  /** Token contract address */
  tokenAddress: `0x${string}`;
  /** Address of the owner (user) */
  ownerAddress: `0x${string}`;
  /** Address of the spender */
  spenderAddress: `0x${string}`;
  /** Amount to be spent */
  value: number;
  /** Current chain */
  chain: Chain;
};
export const requestPermit = async ({
  currency,
  tokenAddress,
  ownerAddress,
  spenderAddress,
  value,
  chain,
}: Params) => {
  const nonceData = await readContract({
    functionName: "nonces",
    address: tokenAddress,
    args: [ownerAddress],
    abi: noncePermitAbi,
    chainId: chain.id,
  });
  console.log("nonceData:", nonceData);
  const types = {
    Permit: [
      { name: "owner", type: "address" },
      { name: "spender", type: "address" },
      { name: "value", type: "uint256" },
      { name: "nonce", type: "uint256" },
      { name: "deadline", type: "uint256" },
    ],
  };
  console.log({ chainId: chain.id });
  let domain: any = {
    name: domainNameMap[currentEnv][currency],
    version: "1",
    chainId: chain.id,
    verifyingContract: tokenAddress,
  };
  console.log("domain", domain);
  if (currentEnv === "prod" && currency === "usdc") {
    const { chainId, ...rest } = domain;
    domain = {
      ...rest,
      salt: ethers.utils.hexZeroPad(ethers.utils.hexlify(137), 32),
    };
  }
  const permitMessage = {
    owner: ownerAddress,
    spender: spenderAddress,
    value: value,
    nonce: Number(nonceData),
    deadline: Math.floor(Date.now() / 1000) + 60 * 60 * 48,
  };
  console.log("permit message", permitMessage);
  try {
    const permit_signature = await signTypedData({
      domain,
      types,
      message: permitMessage,
      primaryType: "Permit",
    });
    console.log("signature:", permit_signature);
    return { ...permitMessage, permit_signature };
  } catch (e: any) {
    if (e?.code === 4001) {
      return { code: "SIGNATURE_REJECTED" };
    }
    return { code: "SIGNATURE_UNAVAILABLE_ERROR" };
  }
};
export default requestPermit;

export const testPermit = async (
  tokenAddress: `0x${string}`,
  owner: string,
  spender: string,
  value: number,
  deadline: number,
  signature: `0x${string}`
) => {
  const { v, r, s } = ethers.utils.splitSignature(signature);
  console.log(v, r, s);

  const config = await prepareWriteContract({
    address: tokenAddress,
    abi: noncePermitAbi,
    functionName: "permit",
    args: [owner, spender, value, deadline, v, r, s],
  });

  const res = await writeContract(config);
  console.log(res);
};

import { combineReducers } from "redux";
import { reducer as notificationsReducer } from "./slices/notifications";
import { reducer as userReducer } from "./slices/user";
import { reducer as mapDataReducer } from "./slices/mapData";
const appReducer = combineReducers({
  notifications: notificationsReducer,
  user: userReducer,
  mapData:mapDataReducer
});
export const rootReducer: typeof appReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    // Resets the store on "USER_LOGOUT" action
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

import { LatLng, LatLngBoundsExpression } from "leaflet";
import { memo, useEffect } from "react";
import { useMap } from "react-leaflet";

type Props = {
  selected?: { x: number; y: number; animate?: boolean; changeBounds?: boolean };
  bounds?: LatLngBoundsExpression;
  isLandsMap?:boolean
};
export const ChangeView: React.FC<Props> = ({ selected, bounds,isLandsMap }) => {
  const map = useMap();
  useEffect(() => {
    bounds && map.setMaxBounds(bounds);
    if (isLandsMap&&selected?.changeBounds) {
      map.setMinZoom(5.49);
      map.setMaxZoom(7);
      map.setZoom(5.6);
    } else if(!isLandsMap&&selected?.changeBounds){
      map.setMinZoom(5.5);
      map.setMaxZoom(7);
      map.setZoom(5.5);
    }
    console.log("changeView")
    selected &&
      map.setView(new LatLng(selected.y, selected.x), undefined, {
        animate: selected.animate || false,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, bounds]);
  return null;
};

export default memo(ChangeView);

import LogoIcon from "assets/logo-icon.svg";
import { twMerge } from "tailwind-merge";
export const AboutMetable: React.FC<{ className?: string }> = ({
  className,
}) => (
  <a
    className={twMerge(
      "flex items-center justify-center gap-2 px-2",
      "whitespace-nowrap text-sm hover:text-secondary",
      className
    )}
    href="https://www.metable.in/"
    target="_blank"
    rel="noreferrer"
  >
    About Metable
    <img className="h-4" alt="metable icon" src={LogoIcon} />
  </a>
);
export default AboutMetable;

import { useWeb3Modal } from "@web3modal/react";
import BasicButton from "components/buttons/Basic";
import { Tooltip } from "components/popovers/Tooltip";
import { twMerge } from "tailwind-merge";
import { useAccount } from "wagmi";
import { ReactComponent as Busd } from "assets/busd.svg";
import { ReactComponent as Usdc } from "assets/usdc.svg";
import { ReactComponent as Usdt } from "assets/usdt.svg";
import { useNetwork } from "wagmi";
import { useEffect, useState } from "react";
import {
  currencyDecimals,
  currencyContract,
  currencyPriceMap,
  landOwnerAddress,
} from "components/cards/MtbMap/utils/constants";
import { LandData } from "apis/fetchMapData";
import { getTransakUrl } from "apis/transak";
import TransparentButton from "components/buttons/Transparent";
import { useTokensBalance } from "hooks/useTokensBalance";
import { balanceOf } from "components/cards/MtbMap/utils/balanceOf";
import { currentEnv } from "env";
import LoadingSpinner from "components/others/LoadingSpinner";
import { FlowError } from "components/cards/MtbMap/utils/errorMap";
import { permitApproveRequest } from "components/cards/MtbMap/utils/permitApproveRequest";
import { getLandStatus } from "components/LeafletMap/LandsGeoJson";
// import { ethers } from "ethers";
export type RequestState =
  | "error"
  | "approve"
  | "sign"
  | "success"
  | "loading"
  | "idle"
  | "tx";
type Props = {
  districtId: number;
  mapId: number;
  selectedLand: LandData;
  updateMap: () => void;
  setRequestState: React.Dispatch<React.SetStateAction<RequestState>>;
  setError: React.Dispatch<React.SetStateAction<FlowError | undefined>>;
};

export type Currencies = "mrkey" | "usdc" | "usdt" | "busd";
export const BuyButtons: React.FC<Props> = ({
  selectedLand,
  updateMap,
  setRequestState,
  setError,
}) => {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { open } = useWeb3Modal();
  const [loading, setLoading] = useState<Currencies>();
  const [isNftAvailable, setIsNftAvailable] = useState(true);
  const [checkInProgress, setCheckInProgress] = useState(false);
  const landStatus = getLandStatus(selectedLand, address);
  const isOwned = !!selectedLand.owner;

  const balance = useTokensBalance();
  const hasEnoughBalance = (currency: Currencies) =>
    (balance[currency]?.["value"] || 0) >=
    currencyPriceMap[currency][selectedLand.size];
  useEffect(() => {
    setCheckInProgress(true);
    balanceOf(landOwnerAddress[currentEnv], selectedLand.nft)
      .then((res) => {
        setIsNftAvailable(Boolean(res));
        setCheckInProgress(false);
      })
      .catch(() => setCheckInProgress(false));
  }, [selectedLand.nft, isOwned]);
  if (checkInProgress)
    return (
      <div className="flex items-center justify-center w-full gap-2 text-lg text-contrast2">
        Checking...{" "}
        <LoadingSpinner className="h-4 fill-contrast2 text-neutral" />
      </div>
    );
  if (landStatus !== "available")
    return {
      locked: (
        <Tooltip tip={`This land is not available to buy`} className="w-full">
          <BasicButton disabled className="w-full bg-contrast2">
            RESERVED
          </BasicButton>
        </Tooltip>
      ),
      owned: (
        <Tooltip tip={`This land already has an owner`} className="w-full">
          <BasicButton disabled className="w-full bg-contrast2">
            OWNED
          </BasicButton>
        </Tooltip>
      ),
      owner: (
        <BasicButton
          disabled
          className="w-full bg-gradient-to-r from-secondary to-primary"
        >
          You own this land!
        </BasicButton>
      ),
      available: (
        <Tooltip tip={`This land is not available to buy`} className="w-full">
          <BasicButton disabled className="w-full bg-contrast2">
            RESERVED
          </BasicButton>
        </Tooltip>
      ),
      error: (
        <Tooltip tip={`This land is not available to buy`} className="w-full">
          <BasicButton disabled className="w-full bg-contrast2">
            TEMPORARILY UNAVAILABLE
          </BasicButton>
        </Tooltip>
      ),
    }[landStatus];
  if (!address)
    return (
      <BasicButton
        onClick={() => open()}
        className="w-full bg-gradient-to-r from-secondary to-primary"
      >
        Connect to web3 to buy
      </BasicButton>
    );
  if (!isNftAvailable) {
    return <div className="text-center">This land is not longer available</div>;
  }
  const buy = (currency: Currencies) => {
    permitApproveRequest(
      address,
      currency,
      setRequestState,
      setLoading,
      (error: FlowError | undefined) => {
        setRequestState("error");
        setError(error);
      },
      chain,
      selectedLand.nft,
      updateMap
    );
  };
  const currencies: [
    keyof typeof currencyContract["dev"],
    string,
    (...args: any) => any,
    React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >
  ][] = [
    ["usdc", "Gasless / ERC20 Permit", () => buy("usdc"), Usdc],
    ["usdt", "ERC20 Approve", () => buy("usdt"), Usdt],
    ["busd", "ERC20 Approve", () => buy("busd"), Busd],
  ];
  return (
    <div className="flex flex-col w-full gap-2">
      <div className="flex w-full gap-1">
        {currencies.map(([curr, description, method, Logo]) => {
          const currency = curr as Currencies;
          return (
            <Tooltip
              key={currency}
              className="grow"
              placement="top"
              tip={
                <CurrencyTip
                  hasEnough={hasEnoughBalance(currency)}
                  description={description}
                  balance={balance}
                  currency={currency}
                />
              }
            >
              <BasicButton
                className={twMerge(
                  "w-full gap-2 transition-all hover:bg-secondary"
                )}
                loading={loading === currency}
                onClick={() => method()}
                disabled={
                  !!selectedLand.owner ||
                  !address ||
                  !!loading ||
                  !hasEnoughBalance(currency)
                }
              >
                <Logo className="h-6" />
                {currency}
              </BasicButton>
            </Tooltip>
          );
        })}
      </div>
      <Tooltip
        className="w-full"
        placement="top"
        tip={
          <CurrencyTip
            hasEnough={hasEnoughBalance("mrkey")}
            description={`Use ${
              currencyPriceMap["mrkey"][selectedLand.size]
            } Mr.Key/s to buy this land! (gasless)`}
            balance={balance}
            currency={"mrkey"}
          />
        }
      >
        <BasicButton
          className={twMerge(
            "w-full gap-2 bg-gradient-to-b from-primary/80 to-secondary/60 hover:from-primary hover:to-secondary"
          )}
          onClick={() => buy("mrkey")}
          loading={loading === "mrkey"}
          disabled={
            !!selectedLand.owner ||
            !address ||
            !!loading ||
            !hasEnoughBalance("mrkey")
          }
        >
          Claim using your Mr.Key
        </BasicButton>
      </Tooltip>
      <a
        href={getTransakUrl(
          address,
          currencyPriceMap["usdc"][selectedLand.size] / currencyDecimals["usdc"]
        )}
        target="_blank"
        rel="noreferrer"
      >
        <TransparentButton className="w-full p-0 m-0">
          Buy USDC with Transak
        </TransparentButton>
      </a>
    </div>
  );
};

type CurrencyTipProps = {
  description: string;
  balance: ReturnType<typeof useTokensBalance>;
  currency: Currencies;
  hasEnough: boolean;
};
export const CurrencyTip: React.FC<CurrencyTipProps> = ({
  description,
  balance,
  currency,
  hasEnough,
}) => {
  return (
    <div className="text-center">
      {description}
      <br />
      {!hasEnough && (
        <>
          <span className="font-light">
            You dont have enough balance to buy this land
          </span>
          <br />
        </>
      )}{" "}
      <span className="font-light">
        Your <b>{currency.toUpperCase()}</b>s:
      </span>{" "}
      <span className="font-light">{balance[currency]?.formatted}</span>
    </div>
  );
};
export default BuyButtons;

import banner from "assets/images/banner.png";
import TransparentButton from "components/buttons/Transparent";
import discordLogo from "assets/images/discord.png";

type Props = {};
export const Footer: React.FC<Props> = () => {
  return (
    <div className="flex flex-col gap-8 pb-4">
      <div className="h-[1px] w-full bg-gradient-to-r from-primary to-secondary" />
      <div className="relative h-32 overflow-hidden rounded-lg">
        <img
          src={banner}
          className="absolute object-cover w-full h-full"
          alt="Metaverse village"
        />
        <div className="absolute z-10 flex items-center justify-between w-full h-full gap-2 px-10">
          <span className="text-lg font-semibold sm:text-4xl">
            Join our <br />
            Discord community
          </span>
          <a
            href="https://discord.gg/metable-701457840455549029"
            target="_blank"
            rel="noreferrer"
          >
            <TransparentButton className="gap-2 px-6 py-2 text-white transition-all border-2 border-contrast bg-primary">
              <img src={discordLogo} alt="discord logo" className="h-5"/>Join
            </TransparentButton>
          </a>
        </div>
        <div className="w-full h-full bg-gradient-to-r from-primary to-secondary opacity-30" />
      </div>
      <div>
        Our Discord community is growing and becoming more active. Join us to
        participate in weekly Quiz Games, special airdrops, and stay updated on
        the project's progress.
      </div>
      <div className="flex flex-col items-center gap-4 mt-10 text-center">
        <div className="flex gap-4 text-contrast">
          <a href="https://www.iubenda.com/privacy-policy/70681632" rel="noreferrer" target="_blank">
            <small className="text-secondary">Privacy Policy</small>
          </a>
          |
          <a href="https://www.metable.in/disclaimer/" rel="noreferrer" target="_blank">
            <small className="text-secondary">Disclaimer</small>
          </a>
        </div>
        <small className="text-primary">
          2023 | CHE-395.659.860 | Metable Gmbh | Lauriedstrasse 1 6300 Zug
          Switzerland
        </small>
      </div>
    </div>
  );
};
export default Footer;

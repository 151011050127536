import mtblToken from "assets/images/metable-coin.png";
import { Tooltip } from "components/popovers/Tooltip";
import { useAccount, useNetwork } from "wagmi";
import { currentEnv } from "env";
import contractMap from "config/contractMap";

export const ImportTokenButton: React.FC = () => {
  const { isConnected } = useAccount();
  const network = useNetwork();

  if (!window.ethereum || !isConnected) return <></>;
  const importToken = async () => {
    if (!window.ethereum) return;
    const isCorrectChain =
      network.chain?.id === contractMap[currentEnv].defaultChain.id;
    if (!isCorrectChain) {
      const hasChanged = await window.ethereum?.request?.({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId:
              "0x" + contractMap[currentEnv].defaultChain.id.toString(16),
          },
        ], // chainId must be in hexadecimal numbers
      });
      console.log(hasChanged);
    }

    const wasAdded = await window.ethereum?.request?.({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: contractMap[currentEnv].tokenContract,
          symbol: contractMap[currentEnv].symbol,
          decimals: 18,
          image: window.location.origin + mtblToken,
        },
      },
    } as any);
    console.log(wasAdded);
  };
  return (
    <Tooltip tip={"Import the MTBL token to Metamask"}>
      <div
        className="flex items-center gap-2 rounded-lg px-2 text-contrast2 hover:bg-primary hover:text-contrast hover:shadow-[0_0_4px_1px] hover:shadow-primary"
        role="button"
        onClick={() => importToken()}
      >
        Import MTBL
        <img alt="Metable token" className="h-6" src={mtblToken} />
      </div>
    </Tooltip>
  );
};

export default ImportTokenButton;

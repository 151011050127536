import Pages from "pages";
import MainLayout from "layouts/Main";
import { Routes, Route, Navigate } from "react-router-dom";
import { usePreloader } from "hooks/usePreloader";
import map_0 from "./assets/map/map_0.png";
import land_s from "./assets/map/land_s.jpg";
import land_m from "./assets/map/land_m.jpg";
import land_l from "./assets/map/land_l.jpg";
import land_xl from "./assets/map/land_xl.png";
import lands_map from "./assets/map/lands_map.jpg";
import { NftReroute } from "pages/NftReroute";
import { useMaintenance } from "hooks/useMaintenance";
function App() {
  usePreloader([map_0, land_s, land_m, land_l, land_xl, lands_map]);

  const showMaintenanceScreen = useMaintenance(false);
  if (showMaintenanceScreen) {
    return (
      <Routes>
        <Route path="*" element={<Pages.Maintenance />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route index element={<Navigate to="home" />} />
      <Route element={<MainLayout />}>
        <Route path="home" element={<Pages.Home />} />
        <Route path="map">
          <Route index element={<Pages.Map />} />
          <Route path=":nft" element={<NftReroute />} />
        </Route>
        <Route path="*" element={<Pages.NotFound />} />
      </Route>
      <Route path="login" element={<Navigate replace to={"/home"} />} />
      {/*         
      <Route element={<OnboardingLayout />}>
        <Route element={<Wrappers.IsConnected isConnected={isConnected} not />}>
          <Route path="login" element={<Pages.Login />} />
        </Route>
      </Route> */}
      <Route path="*" element={<Pages.NotFound />} />
    </Routes>
  );
}

export default App;

import { XMarkIcon } from "@heroicons/react/24/outline";
import TransparentButton from "components/buttons/Transparent";
import ImageDiv from "components/others/ImageDiv";
import ProgressCountDown from "components/others/Progress/CountDown";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import AppNotification from "types/notifications";

/** Returns text and bg tailwind color classes (text,bg) depending on the notification type */
const twTypeColors = (type?: AppNotification["type"]): [string, string] => {
  switch (type) {
    case "SUCCESS":
      return ["text-purple-400", "bg-purple-400"];
    case "DANGER":
      return ["text-red-400", "bg-red-400"];
    default:
      return ["text-slate-400", "bg-slate-400"];
  }
};

type Props = { duration?: number; remove?: () => void } & AppNotification &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
export const NotificationToast: React.FC<Props> = ({
  title,
  description,
  imageSrc,
  type,
  className,
  duration = 6,
  remove,
  ...attributes
}) => {
  const typeColors = twTypeColors(type);
  const [isCountdownPaused, setIsCountdownPaused] = useState(false);
  const [scheduledDeletion, setScheduledDeletion] = useState(false);
  return (
    <div
      className={twMerge(
        "flex w-80 grow animate-fade-in-height flex-col overflow-hidden rounded-2xl bg-white dark:bg-slate-700 shadow-lg",
        className,
        scheduledDeletion&&"transition-opacity opacity-0"
      )}
      onMouseOver={() => setIsCountdownPaused(true)}
      onMouseOut={() => setIsCountdownPaused(false)}
      onTransitionEnd={(e)=>{
        if(remove && scheduledDeletion){
          remove()
        }
      }}
      {...attributes}
    >
      {/* Header */}
      <div className="flex items-center justify-between px-4 py-2 overflow-hidden text-sm dark:bg-slate-600 bg-slate-100">
        {/* Title */}
        <div
          className={twMerge("overflow-ellipsis font-medium text-slate-100", typeColors[0])}
        >
          {title}
        </div>
        {/* CloseButton */}
        <TransparentButton
          onClick={()=>setScheduledDeletion(true)}
          className="h-6 p-5 px-4 -m-4 rounded-l-none text-slate-400 bg-slate-200 dark:bg-slate-600"
        >
          Chiudi <XMarkIcon className="h-4" />
        </TransparentButton>
      </div>
      {/* Body */}
      <div className="flex gap-3 px-4 py-2 text-sm dark:text-slate-300 text-slate-600">
        {/* Image (optional) */}
        {imageSrc && (
          <ImageDiv className="w-12 h-12 rounded-lg" src={imageSrc} />
        )}
        {/* Description */}
        <div className="w-full">{description}</div>
      </div>
      {/* Footer (progress bar) */}
      <div className="px-4 pb-3">
        <ProgressCountDown
          handleEnd={()=>setScheduledDeletion(true)}
          duration={duration}
          className={typeColors[1]}
          paused={isCountdownPaused}
        />
      </div>
    </div>
  );
};

export default NotificationToast;

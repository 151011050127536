import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import contractMap from "config/contractMap";
import { currentEnv } from "env";
import { configureChains, createConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { InjectedConnector } from "wagmi/connectors/injected";

const defaultChain = contractMap[currentEnv].defaultChain;

export const projectId = process.env.REACT_APP_PROJECT_ID!;

const { publicClient, webSocketPublicClient } = configureChains(
  [defaultChain],
  [w3mProvider({ projectId }), publicProvider()]
);

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    ...w3mConnectors({
      projectId,
      version: 2,
      chains: [defaultChain]
    }),
    new InjectedConnector({ chains: [defaultChain] }),
  ],
  publicClient,
  webSocketPublicClient,
});

export const ethereumClient = new EthereumClient(wagmiConfig, [defaultChain]);

export default wagmiConfig;

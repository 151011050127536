import TransparentButton from "components/buttons/Transparent";
import NewsCard from "pages/Home/News/Card";
import articles from "pages/Home/News/articles";
export const News: React.FC = () => {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-end justify-between mb-4">
        <div className="text-3xl font-bold">News</div>
        <TransparentButton>View all</TransparentButton>
      </div>
      <div className="flex flex-col justify-between w-full gap-4 md:flex-row">
        {articles.slice(0, 3).map((data, i) => (
          <NewsCard {...data} key={i} />
        ))}
      </div>
      {/** Older news ? */}
      <div className="flex flex-col justify-between w-full gap-4 md:flex-row">
        {articles.slice(3, 6).map((data, i) => (
          <NewsCard mini {...data} key={i} />
        ))}
      </div>
    </div>
  );
};

export default News;

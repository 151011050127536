import heroPng from "assets/images/hero.jpg";
import TransparentButton from "components/buttons/Transparent";
import HeroModal from "pages/Home/HeroModal";
import { NewsModal } from "pages/Home/News/Card";
import articles from "pages/Home/News/articles";
import { useState } from "react";

type Props = {};
export const Hero: React.FC<Props> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const articleId = 1;
  return (
    <div className="relative w-full h-56 text-3xl font-bold sm:h-80 sm:text-5xl">
      <img
        src={heroPng}
        className="absolute object-cover w-full h-full"
        alt="Metaverse village"
      />
      <div className="absolute inset-0 z-10 flex flex-col items-start justify-center gap-8 mx-10">
        <span>
          Metable Land Sale <br />
          July 19th
          <TransparentButton
            className="text-base"
            onClick={() => setIsModalOpen(true)}
          >
            Read more...
          </TransparentButton>
          <NewsModal
            {...articles.find(({ id }) => id === articleId)!}
            isOpen={isModalOpen}
            close={() => setIsModalOpen(false)}
          />
        </span>
        <div className="flex justify-center w-full sm:justify-start">
          <HeroModal />
        </div>
      </div>
    </div>
  );
};
export default Hero;

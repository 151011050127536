import { twMerge } from "tailwind-merge";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  bgClassName?: string;
  src?: React.ImgHTMLAttributes<HTMLImageElement>["src"];
};

export const GradientCard: React.FC<Props> = ({
  className,
  src,
  bgClassName,
  children,
  ...attributes
}) => {
  return (
    <div
      className={twMerge(
        "rounded-2xl bg-gradient-to-tr from-secondary to-primary",
        "overflow-hidden transition-all hover:p-1 group-hover:p-1",
        className
      )}
      {...attributes}
    >
      <div
        className={twMerge(
          "h-full w-full overflow-hidden rounded-xl",
          bgClassName
        )}
      >
        {children
          ? children
          : src && (
              <img
                alt="Land"
                src={src}
                className="h-full w-full object-cover"
              />
            )}
      </div>
    </div>
  );
};
export default GradientCard;

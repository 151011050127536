import { XMarkIcon } from "@heroicons/react/24/solid";
import BasicButton from "components/buttons/Basic";
import ImportTokenButton from "components/buttons/ImportToken";
import { NavButton } from "components/navbars/Sidebar";
import { pageRoutes } from "components/navbars/Sidebar/Sidebar";
import AboutMetable from "components/others/AboutMetable";
import { Fragment } from "react";
import { twMerge } from "tailwind-merge";
import { Tooltip } from "components/popovers/Tooltip";

export const MobileMenu: React.FC<{ open: boolean; close: () => void }> = ({
  open,
  close,
}) => {
  return (
    <div
      className={twMerge(
        "grid grid-rows-[1fr]",
        "absolute bottom-0 left-0 w-full translate-y-full",
        "bg-neutral shadow-lg shadow-neutral2 sm:hidden",
        "border-t border-primary/50 transition-all",
        !open && "grid-rows-[0fr] border-none"
      )}
    >
      <div className="flex flex-col items-center overflow-hidden">
        {pageRoutes.map((routeArray, i) => {
          return (
            <Fragment key={i}>
              <NavButton routeArray={routeArray} className="my-4 text-xl" />
              {i < pageRoutes.length - 1 && (
                <div className="w-3/4 border-b border-contrast/50" />
              )}
            </Fragment>
          );
        })}
        <div className="mt-8">
          <ImportTokenButton />
        </div>
        <div className="flex items-center justify-between w-full p-2 mt-4">
          <AboutMetable className="m-0 text-lg font-light text-contrast/50" />
          <BasicButton className="bg-neutral2" onClick={close}>
            <XMarkIcon className="h-5" />
          </BasicButton>
        </div>
      </div>
    </div>
  );
};
export default MobileMenu;

import {
  prepareWriteContract,
  waitForTransaction,
  writeContract,
} from "wagmi/actions";
import approveAbi from "./approveAbi.json";
type Params = {
  /** Token contract address */
  tokenAddress: `0x${string}`;
  /** Address of the spender */
  spenderAddress: `0x${string}`;
  /** Amount to be spent */
  value: number;
};
export const requestApprove = async ({
  tokenAddress,
  spenderAddress,
  value,
}: Params) => {
  const config = await prepareWriteContract({
    address: tokenAddress,
    abi: approveAbi,
    functionName: "approve",
    args: [spenderAddress, value],
  });

  const res = await writeContract(config);
  console.log(res);
  const tx = await waitForTransaction(res);
  
  return tx;
};
export default requestApprove;

import { currentEnv } from "env";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./store";
import App from "./App";
import "./index.tailwind.css";
import { NotifcationsContainer } from "components/notifications/Container";
import { applyTheme } from "hooks/useColorScheme";
import { WagmiConfig } from "wagmi";
import { wagmiConfig, ethereumClient, projectId } from "config/wagmiConfig";
import { Web3Modal } from "@web3modal/react";
import contractMap from "config/contractMap";





const store = configureStore({});
applyTheme(store.getState().user.prefersColorScheme);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <WagmiConfig
    config={wagmiConfig}
  >
    <Provider store={store}>
      <BrowserRouter>
        <NotifcationsContainer />
        <App />
      </BrowserRouter>
    </Provider>
    <Web3Modal
      defaultChain={contractMap[currentEnv].defaultChain}
      projectId={projectId}
      ethereumClient={ethereumClient}
      themeVariables={{
        "--w3m-font-family": "Alexandria, sans-serif",
        "--w3m-accent-color": "#7717F5",
        "--w3m-background-color": "#7717F5",
      }}
    />
  </WagmiConfig>
);

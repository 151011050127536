import { landsContract } from "components/cards/MtbMap/utils/constants";
import balanceOfAbi from "./balanceOfAbi.json";
import { currentEnv } from "env";
import { readContract } from "wagmi/actions";

export const balanceOf = async (owner: `0x${string}`, id: string) => {
  const res = await readContract({
    address: landsContract[currentEnv],
    abi: balanceOfAbi,
    functionName: "balanceOf",
    args: [owner, id],
  });
  // console.log("balanceOf",Number(res))
  return Number(res);
};

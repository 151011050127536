import themeColors, { merged } from "theme.tailwind";
import { GeoJSON, ImageOverlay, useMap } from "react-leaflet";
//import geoJson from "assets/map/land_geo.json";
import { GeoJsonObject } from "geojson";
import { useEffect, useState } from "react";
import landGeo from "assets/map/land_geo.json";
import { LatLng, PathOptions, Point } from "leaflet";
import MapLands from "assets/map/lands_map.jpg";
import { LandData } from "apis/fetchMapData";
import isMobile from "utils/isMobile";
import { landSizes } from "components/cards/MtbMap/Lands";
import { useAccount } from "wagmi";
import { landsBounds, logoBounds } from "components/LeafletMap/utils";
import MetableLogo from "assets/logo-icon.svg";
import ChangeView from "components/LeafletMap/ChangeView";

type Props = {
  selectedLand: LandData | undefined;
  setSelectedLand: (land: LandData | undefined) => void;
  mapData: LandData[];
};
const getter = {
  mapData: () => [] as LandData[],
  address: () => undefined as string | undefined,
};
const popupOffset = new Point(0, -5);
const center = landsBounds.getCenter();
const centerCoords = {
  x: center.lat,
  y: center.lng,
};
export type MtbLand = {
  id: number;
  x: number;
  y: number;
  size: number;
};
export const getLandStatus = (land: LandData, user?: string) => {
  if (land!.status === "locked") return "locked";
  if (land!.status === "checkout_error") return "error";
  if (!!land!.owner && land!.owner === user) return "owner";
  if (!!land!.owner) return "owned";
  return "available";
};
export const LandsGeoJson: React.FC<Props> = ({
  selectedLand,
  mapData,
  setSelectedLand,
}) => {
  const { address } = useAccount();
  const [hovered, setHovered] = useState<LandData>();

  const map = useMap();
  const center = {
    x: centerCoords.x,
    y: centerCoords.y,
    animate: false,
    changeBounds: true,
    id: undefined,
  };
  const [selectedPolygon, setSelectedPolygon] = useState<{
    x: number;
    y: number;
    animate?: boolean;
    changeBounds?: boolean;
    id: number | undefined;
  }>(center);
  useEffect(() => {
    map.setMaxBounds(landsBounds);
    map.setMinZoom(5.49);
    map.setMaxZoom(7);
    map.setZoom(5.6);
    map.setView(new LatLng(center.y, center.x), undefined, {
      animate: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  getter.mapData = () => mapData;
  getter.address = () => address;
  return (
    <>
      <ChangeView selected={selectedPolygon} />
      <GeoJSON
        data={landGeo as GeoJsonObject}
        style={(feature) => {
          const { id }: MtbLand = feature?.properties;
          const landData = getter.mapData().find((land) => land.id === id);
          const status = landData
            ? getLandStatus(landData, getter.address())
            : "error";
          const statusColors = {
            locked: themeColors.danger,
            owned: themeColors.danger,
            error: themeColors.yellow["500"],
            owner: themeColors.blue["400"],
            available: themeColors.green["400"],
          };
          let style: PathOptions = {
            stroke: true,
            color: statusColors[status],
            fillColor: statusColors[status],
            fillOpacity: 0.4,
          };
          switch (id) {
            case hovered?.id:
              style.fillOpacity = 0.6;
              break;
            case selectedLand?.id:
              style.fillOpacity = 0.8;
              break;
          }
          return style;
        }}
        onEachFeature={async (feature, layer) => {
          if (!getter.mapData()) return;
          layer.bindTooltip(
            `<div class="text-2xl w-full h-full flex items-center justify-center" style="text-shadow: 0 0 4px ${merged.neutral}, 0 0 4px ${merged.neutral}, 0 0 4px ${merged.neutral}">
              ${feature.properties.id}
            </div>`,
            {
              permanent: true,
              direction: "center",
            }
          );
          const featureId = feature.properties.id;
          layer.on({
            click: (_e) => {
              const data = getter
                .mapData()
                .find((land) => land.id === featureId);
              setSelectedLand(data);
              setSelectedPolygon({
                ...feature.properties,
                animate: true,
                changeBounds: false,
              });
            },
            mouseover: async (_e) => {
              if (isMobile) return;
              const data = getter
                .mapData()
                .find((land) => land.id === featureId);
              if (!data) return;
              setHovered(data);
              layer.setPopupContent(tooltipContent(data));
              layer.openPopup();
            },
            mouseout: (_e) => {
              setHovered((curr) =>
                curr?.id === feature.properties.id ? undefined : curr
              );
              layer.closePopup();
            },
          });
          const popupOptions = {
            className: "leaflet-popup-custom",
            direction: "auto",
            offset: popupOffset,
            autoPan:false,
          };
          if (!isMobile) {
            layer.bindPopup(() => {
              const data = getter
                .mapData()
                .find((land) => land.id === featureId);
              return data ? tooltipContent(data) : "";
            }, popupOptions);
          }
          const tooltipContent = (landData: LandData) => {
            const status = getLandStatus(landData, getter.address());
            const statusMessage = {
              locked: `<div class="text-contrast2"> RESERVED</div>`,
              error: `<div class="text-yellow-500">TEMPORARILY UNAVAILABLE</div>`,
              owned: `<div class="text-contrast2"> OWNED</div>`,
              owner: `<div class="text-blue-400 font-semibold"> OWNED</div>`,
              available: `<div class="font-semibold text-green-400">AVAILABLE</div>`,
            };
            return `
          <div class="flex flex-col gap-2 w-full">
              <div class="flex items-center select-none gap-2 w-full">
                <div class="font-bold text-base">Land ${landData.id} </div>
                <div>-</div>
                <div class="w-6 h-6 ${
                  landSizes[landData.size][1]
                } flex items-center justify-center text-base border border-contrast/60 rounded">
                            ${landSizes[landData.size][0]}
                </div>
              </div>
                <div class="flex justify-center w-full text-base">
                ${statusMessage[status]}
                </div>
          </div>`;
          };
        }}
      />
      <ImageOverlay
        url={MapLands}
        bounds={landsBounds}
        opacity={1}
        zIndex={10}
      />
      <ImageOverlay
        url={MetableLogo}
        bounds={logoBounds}
        opacity={1}
        zIndex={10}
      />
    </>
  );
};
export default LandsGeoJson;

import { MapContainer } from "react-leaflet";
import { CRS } from "leaflet";
import { twMerge } from "tailwind-merge";
import MapsGeoJson from "components/LeafletMap/MapsGeoJson";
import LandsGeoJson from "components/LeafletMap/LandsGeoJson";
import "leaflet/dist/leaflet.css";
import "./custom.css";
import { LandData } from "apis/fetchMapData";
import { mapsBounds } from "components/LeafletMap/utils";

export type MtbMap = {
  id: number;
  x: number;
  y: number;
};
type Props = {
  districtId: number;
  selected: MtbMap | undefined;
  setSelected: React.Dispatch<React.SetStateAction<MtbMap | undefined>>;
  className?: string;
  selectedLand: LandData | undefined;
  setSelectedLand: (land: LandData | undefined) => void;
  mapData: LandData[];
};
export const LeafletMap: React.FC<Props> = ({
  districtId,
  selected,
  setSelected,
  selectedLand,
  setSelectedLand,
  mapData,
  className,
}) => {
  return (
    <MapContainer
      bounds={mapsBounds}
      maxBounds={mapsBounds}
      center={mapsBounds.getCenter()}
      maxZoom={8}
      zoom={5.5}
      minZoom={5.2}
      scrollWheelZoom
      className={twMerge("z-0 h-full w-full", className)}
      maxBoundsViscosity={1}
      crs={CRS.Simple}
      zoomControl={false}
      attributionControl={false}
      doubleClickZoom={false}
    >
      {/* <ImageOverlay url={logoBackground} bounds={mapsBounds} opacity={1} zIndex={10} /> */}
      {selected && mapData.length ? (
        <LandsGeoJson
          selectedLand={selectedLand}
          mapData={mapData}
          setSelectedLand={setSelectedLand}
        />
      ) : (
        <MapsGeoJson
          districtId={districtId}
          selected={selected}
          setSelected={setSelected}
        />
      )}
    </MapContainer>
  );
};

export default LeafletMap;

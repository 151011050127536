import snarkdown from "snarkdown";

interface MarkdownProps {
  children: string;
}

export const Markdown: React.FC<MarkdownProps> = ({ children }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: snarkdown(children),
      }}
    />
  );
};
export default Markdown;

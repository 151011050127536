export const articles = [
  {
    title: "Save the date: International Land Sale July 19th 2023",
    id: 1,
    date: new Date("2023-06-26"),
  },
  {
    title: "Save the date: Land Pre-Sale July 12th 2023",
    id: 7,
    date: new Date("2023-06-26"),
  },
  {
    title:
      "Over $50,000 Worth of Metable Tokens up for Grabs in 2 Airdrop Campaigns!",
    id: 3,
    date: new Date("2023-06-05"),
  },
  {
    title: "Metable Token Generation Event, save the date June 12th",
    id: 4,
    date: new Date("2023-06-05"),
  },
  {
    title: "Metable Token Distribution Event for airdrops winners 2022",
    id: 5,
    date: new Date("2023-06-05"),
  },
  {
    title: "Mr. Key Holders: Token Airdrop and Land Pre-Sale Awaits!",
    id: 6,
    date: new Date("2023-06-05"),
  },
  {
    title: "Save the date: Italian Land Sale July 12th 2023",
    id: 2,
    date: new Date("2023-06-05"),
  },
];

export default articles;

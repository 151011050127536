import BasicButton from "components/buttons/Basic";
import { twMerge } from "tailwind-merge";
import { Tooltip } from "components/popovers/Tooltip";
import { useNavigate } from "react-router-dom";
type Props = {};
export const HeroModal: React.FC<Props> = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <Tooltip
        placement="top"
        tip="The land sale will be available for everyone on July 19th!"
      > */}
      <BasicButton
        className={twMerge(
          "gap-2 bg-gradient-to-r from-primary to-secondary transition-all hover:shadow-[0_0_5px_5px]",
          "p-4 py-1 text-3xl font-light shadow-[0_0_15px_5px] shadow-contrast"
        )}
        onClick={() => navigate("/map")}
      >
        Buy Lands
      </BasicButton>
      {/* </Tooltip> */}
    </>
  );
};
export default HeroModal;

import endpoint from "apis/endpoints";

export const buyLand = async (
  landNft:string,
  currency: "usdc" | "usdt" | "busd" | "mrkey",
  userAddress: string,
  permitData:
    | {
        permit_signature: string;
        spender: string;
        value: string;
        nonce: string;
        deadline: string;
      }
    | {} = {}
) => {
  const res = await fetch(`${endpoint}land/buy/${landNft}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      currency,
      address: userAddress,
      ...permitData,
    }),
  });
  console.log(res);
  return res;
};

/*
  Possible results: 
  {status: "polling" | "completed" | error string}
*/
export const getLandTransaction = async (tokenId: string) => {
  const res = await fetch(
    `${endpoint}governance/get/order?nft=${tokenId}`
  ).then((res) => res.json());
  console.log(res);
  // throw new Error()
  return res;
};

export const pollLandTransaction = async (token_id: string) => {
  const TIMEOUT = 1000;
  const res = await getLandTransaction(token_id);
  console.log(res);
  if (res.status !== "polling") {
    return res;
  }
  return await new Promise((resolve) => {
    setTimeout(
      async () => resolve(await pollLandTransaction(token_id)),
      TIMEOUT
    );
  });
};

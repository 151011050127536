import { polygonMumbai, polygon } from "wagmi/chains";

export const contractMap = {
    dev: {
      defaultChain: polygonMumbai,
      tokenContract: "0xf9727F52E2a9d401f746fe8086DCBc5239e39cB8",
      symbol:"MTBL"
    },
    prod: {
      defaultChain: polygon,
      tokenContract: "0x0C1eb50007974C4E017A6ECcF751110bAc287eBB",
      symbol:"MTBL"
    },
  };

export default contractMap
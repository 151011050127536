import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/reducers";
import AppNotification from "types/notifications";

const initialState = {
  toasts: [] as AppNotification[],
};
export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (
      state,
      { payload }: PayloadAction<Omit<AppNotification, "id">>
    ) => {
      state.toasts.push({ ...payload, id: crypto.randomUUID() });
      return state;
    },
    removeNotification: (state, { payload }: PayloadAction<string>) => {
      state.toasts.splice(
        state.toasts.findIndex((t) => t.id === payload),
        1
      );
      return state;
    },
  },
});

export const notificationsSelectors = {
  toasts: (state: RootState) => state.notifications.toasts,
};

const { actions, reducer } = notificationsSlice;
export { reducer, actions as notificationsActions };

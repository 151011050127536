import { ArrowUturnLeftIcon, MapPinIcon } from "@heroicons/react/24/solid";
import fetchMapData, { LandData, deleteMapDataCache } from "apis/fetchMapData";
import { makeTokenId } from "apis/tokenId";
import { LeafletMap } from "components/LeafletMap";
import { MtbMap } from "components/LeafletMap/LeafletMap";
import BasicButton from "components/buttons/Basic";
import MtbMapCard from "components/cards/MtbMap";
import useUrlHandler from "hooks/useUrlHandler";
import { RefreshCountdown } from "pages/Map/RefreshCountdown";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import merged from "theme.tailwind";
export const populatedMapData = async (districtId: number, mapId: number) => {
  const fetchedMap = await fetchMapData(districtId, mapId);
  if (fetchedMap) {
    const placeholderLands = createLands(districtId, mapId);
    const lands = placeholderLands.map((placeholder) => {
      const data = fetchedMap.find((land) => land.id === placeholder.id);
      return data || placeholder;
    });
    return lands;
  }
  return [];
};
export const Map: React.FC = () => {
  const districtId = 1;
  const [selected, setSelected] = useState<MtbMap>();
  const [mapData, setMapData] = useState<LandData[]>([]);
  const [selectedLand, setSelectedLand] = useState<LandData>();
  const isLoading = useUrlHandler(
    setSelected,
    setSelectedLand,
    setMapData,
    populatedMapData,
    districtId,
    selected?.id,
    selectedLand?.id
  );


  const updateMap = async () => {
    if (!selected) return;
    deleteMapDataCache(districtId, selected.id);
    const lands = await populatedMapData(districtId, selected.id);
    setMapData(lands);
    if (!lands) return;
    const updatedLand = lands.find((land) => land.id === selectedLand?.id);
    updatedLand && setSelectedLand(updatedLand);
  };
  useEffect(() => {
    if (selected) {
      populatedMapData(districtId, selected.id).then((res) => setMapData(res));
    }
  }, [selected]);
  return (
    <>
      <div
        className={twMerge(
          "relative flex h-full w-full flex-col overflow-hidden opacity-0 transition-all duration-500",
          !isLoading && "opacity-100"
        )}
      >
        <div className={twMerge("relative grow bg-neutral")}>
          <div className={twMerge("sticky top-0 left-0 h-full w-full")}>
            <LeafletMap
              districtId={1}
              selected={selected}
              setSelected={setSelected}
              selectedLand={selectedLand}
              setSelectedLand={setSelectedLand}
              mapData={mapData}
            />
          </div>
          <div className="absolute inset-0 z-0 pointer-events-none ">
            {selected && <RefreshCountdown update={updateMap} />}
            <div className="relative flex flex-col max-h-full gap-2 px-1 m-4 mt-0 overflow-y-scroll pointer-events-auto no-scrollbar w-fit">
              {selected ? (
                <>
                  <div
                    className={twMerge(
                      "sticky top-2 z-0 flex flex-col text-2xl font-semibold text-contrast",
                      selectedLand && "hidden md:block"
                    )}
                  >
                    <div
                      className="flex justify-between w-full gap-3"
                      style={{ textShadow: "0 0 4px #000000" }}
                    >
                      Founding District
                      <BasicButton
                        className="gap-2 text-base"
                        onClick={() => {
                          setSelected(undefined);
                          setSelectedLand(undefined);
                        }}
                      >
                        <ArrowUturnLeftIcon className="h-6" />
                        Back
                      </BasicButton>
                    </div>{" "}
                    <div className="z-0 flex items-center justify-between w-full gap-3 p-2 my-2 rounded-xl bg-neutral/90">
                      <span
                        className="text-white"
                        style={{
                          textShadow: `0 0 5px ${merged.primary}, 0 0 5px ${merged.primary}, 0 0 5px ${merged.primary}`,
                        }}
                      >
                        Map #{selected.id}
                      </span>
                      <div
                        className="flex items-center gap-2"
                        style={{
                          textShadow: `0 0 5px ${merged.primary}, 0 0 5px ${merged.primary}, 0 0 5px ${merged.primary}`,
                        }}
                      >
                        <MapPinIcon className="h-6 stroke stroke-white text-primary" />
                        <div className="text-sm text-white">
                          {selected.x},{selected.y}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="sticky top-0 z-10">
                  <SearchBar
                    className="w-full max-w-sm border border-primary bg-neutral/90"
                    placeholder="Search lands..."
                  />
                </div> */}
                </>
              ) : (
                <>
                  <div
                    className={twMerge(
                      "sticky z-10 pt-2 text-2xl font-semibold text-contrast",
                      selectedLand && "hidden md:block"
                    )}
                    style={{ textShadow: "0 0 4px #000000" }}
                  >
                    Founding District
                  </div>

                  {/* <div className="sticky top-0 z-10">
                  <SearchBar
                    className="w-full max-w-sm border border-primary bg-neutral/90"
                    placeholder="Search lands..."
                  />
                </div> */}
                </>
              )}
              {selected && (
                <MtbMapCard
                  mtbMap={selected}
                  close={() => setSelectedLand(undefined)}
                  selectedLand={selectedLand}
                  updateMap={updateMap}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export type LandSize = 0 | 1 | 2 | 3;
export const landsAmount = [28, 11, 1, 0];
export const specialLandsAmount = [0, 0, 0, 1];
const createLands = (districtId: number, mapId: number) => {
  const sizes = mapId === 116 ? specialLandsAmount : landsAmount;
  let totalAmount = 0;
  const arr: LandData[] = [];
  for (let i = sizes.length - 1; i >= 0; i--) {
    const amount = sizes[i];
    for (let j = 0; j < amount; j++) {
      const size = i as [0, 1, 2, 3][number];
      totalAmount++;
      arr.push({
        id: totalAmount,
        district_id: districtId,
        map_id: mapId,
        size,
        cart_expire: 0,
        owner: undefined,
        nft: makeTokenId(districtId, mapId, totalAmount, size),
        status: "available",
      });
    }
  }

  return arr;
};
export default Map;

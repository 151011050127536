import isMobile from "utils/isMobile";
import { landSizes } from "components/cards/MtbMap/Lands";
import themeColors from "theme.tailwind";
import fetchMapData from "apis/fetchMapData";
import { GeoJSON, ImageOverlay, useMap } from "react-leaflet";
import geoJson from "assets/map/map_geo.json";
import { MtbMap } from "components/LeafletMap/LeafletMap";
import { GeoJsonObject } from "geojson";
import Map0 from "assets/map/map_0.png";
import Map2 from "assets/map/map_2.png";
import { useEffect, useState } from "react";
import { LatLng, LatLngBounds, Layer } from "leaflet";
import { specialLandsAmount, landsAmount } from "pages/Map/Map";
import { mapsBounds } from "components/LeafletMap/utils";
import fetchDistrictMapData from "apis/fetchDistrictMapData";

const getLandsAmount = (id: number) => {
  return id === 116 ? specialLandsAmount : landsAmount;
};
const center = mapsBounds.getCenter();
const centerCoords = {
  x: center.lat,
  y: center.lng,
};
type Props = {
  districtId: number;
  selected: MtbMap | undefined;
  setSelected: React.Dispatch<React.SetStateAction<MtbMap | undefined>>;
};
const getter = {
  soldOutMaps: [] as number[],
};
export const MapsGeoJson: React.FC<Props> = ({
  districtId,
  selected,
  setSelected,
}) => {
  const [hovered, setHovered] = useState<MtbMap>();
  const [, setSoldOutMaps] = useState<number[]>([]);
  const [layers, setLayers] = useState<Layer[]>();
  const gridBounds = new LatLngBounds([
    [0, 0],
    [24, 24],
  ]);
  const map = useMap();
  const center = {
    x: centerCoords.x,
    y: centerCoords.y,
    animate: false,
    changeBounds: true,
    id: undefined,
  };
  useEffect(() => {
    map.setMaxBounds(mapsBounds);
    map.setMinZoom(5.5);
    map.setMaxZoom(7);
    map.setZoom(5.5);
    map.setView(new LatLng(center.y, center.x), undefined, {
      animate: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    fetchDistrictMapData(districtId).then((res) => {
      const soldOutIds = res.map((m) => m.map);
      getter.soldOutMaps = soldOutIds;
      setSoldOutMaps(soldOutIds);
      layers?.forEach((l) => l.fireEvent("update", soldOutIds));
    });
  }, [districtId, layers]);
  return (
    <>
      <GeoJSON
        data={geoJson as GeoJsonObject}
        style={(feature) => {
          const { id }: MtbMap = feature?.properties;
          const style = {
            fillColor: themeColors.neutral2,
            color: "unset",
            fillOpacity: 0.5,
          };
          switch (id) {
            case hovered?.id:
              style.fillOpacity = 0.7;
              style.fillColor =
                id === 116 ? themeColors.danger : themeColors.primary;
              break;
            case selected?.id:
              style.fillOpacity = 0.9;
              style.fillColor = themeColors.neutral2;
              style.color = themeColors.primary;
              break;
          }
          if (getter.soldOutMaps.includes(id)) {
            style.fillColor = themeColors.danger;
          }
          return style;
        }}
        onEachFeature={(feature, layer) => {
          setLayers((prev) => (prev ? [...prev, layer] : [layer]));
          const { id }: MtbMap = feature?.properties;
          layer.bindTooltip(
            `<div style="text-shadow: 0 0 3px black, 0 0 3px black;">${id}</div>`,
            {
              permanent: true,
              direction: "center",
            }
          );
          const popupOptions = {
            className: "leaflet-popup-custom",
          };
          const tooltipContent = (
            landsDataF: "loading" | number[] = "loading",
            soldMaps?: number[]
          ) => {
            const isSoldOut = soldMaps?.includes(id);
            return `
      <div class="flex flex-col select-none">
        <div class="font-bold">Map ${id}</div>
        ${
          isSoldOut
            ? `<b class="w-full text-center text-lg text-yellow-200">SOLD OUT</b>`
            : `<div class="mb-1 font-light">Available lands:</div>${
                landsDataF === "loading"
                  ? "loading..."
                  : `<div class="flex flex-col">
            ${landSizes
              .map(([label, classes], i) => {
                const totalLands = getLandsAmount(feature.properties.id)[i];
                if (!totalLands) return "";
                return `
                  <div class="flex gap-2 items-center">
                    <div class="w-4 h-4 ${classes} flex items-center justify-center">
                        ${label}
                    </div>
                    <div>
                      ${landsDataF[i]}
                      <span class="opacity-50">/${totalLands}</span>
                    </div>
                  </div>`;
              })
              .join("")}
          </div>`
              }`
        }
      </div>`;
          };
          if (!isMobile) {
            layer.bindPopup(() => {
              return tooltipContent();
            }, popupOptions);
          }
          layer.on({
            click: (_e) => {
              // // console.log(x, y);
              if (id === 116) return;
              setSelected((prev) =>
                prev?.id === id ? undefined : feature.properties
              );
            },
            update: (_evt) => {
              if (getter.soldOutMaps.includes(id)) {
                layer.setTooltipContent(
                  `<div class="text-danger font-bold" style="text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black;">${id}</div>`
                );
              }
            },
            mouseover: async (_e) => {
              if (isMobile) return;
              setHovered(feature.properties);
              layer.openPopup();
              fetchMapData(districtId, feature.properties.id).then((lands) => {
                const landsAmt = lands.reduce<number[]>((acc, curr) => {
                  let newAcc = [...acc];
                  newAcc[curr.size]--;
                  return newAcc;
                }, getLandsAmount(feature.properties.id));
                layer.setPopupContent(
                  tooltipContent(landsAmt, getter.soldOutMaps)
                );
              });
            },
            mouseout: (_e) => {
              setHovered((curr) =>
                curr?.id === feature.properties.id ? undefined : curr
              );
              layer.closePopup();
            },
          });
        }}
      />
      <ImageOverlay url={Map0} bounds={mapsBounds} opacity={1} zIndex={10} />
      <ImageOverlay url={Map2} bounds={gridBounds} opacity={0.1} zIndex={10} />
    </>
  );
};
export default MapsGeoJson;

import { MtbMap } from "components/LeafletMap/LeafletMap";
import BuyButtons, { RequestState } from "components/cards/MtbMap/BuyButtons";
import { landSizes } from "components/cards/MtbMap/Lands";
import { ImagesCarousel } from "components/others/ImagesCarousel";
import { useRef } from "react";
import { twMerge } from "tailwind-merge";

import landS from "assets/map/land_s.jpg";
import landM from "assets/map/land_m.jpg";
import landL from "assets/map/land_l.jpg";
import landXL from "assets/map/land_xl.png";
import { LandData } from "apis/fetchMapData";
import {
  currencyDecimals,
  currencyPriceMap,
  listingPriceMap,
} from "components/cards/MtbMap/utils/constants";
import { usdFormatter } from "utils/currencyFormatter";
import TransactionLink from "components/TransactionLink";
import CopyButton from "components/buttons/Copy";
import shorten from "utils/shorten";
import { ShareIcon } from "@heroicons/react/24/outline";
import { FlowError } from "components/cards/MtbMap/utils/errorMap";
export const landSizeImages = [landS, landM, landL, landXL];

type Props = {
  districtId: number;
  mtbMap: MtbMap;
  selectedLand: LandData | undefined;
  updateMap: () => void;
  setRequestState: React.Dispatch<React.SetStateAction<RequestState>>;
  setError: React.Dispatch<React.SetStateAction<FlowError | undefined>>;
};

export const MtbMapInfo: React.FC<Props> = ({
  districtId,
  mtbMap,
  selectedLand,
  updateMap,
  setRequestState,
  setError,
}) => {
  const landRef = useRef<HTMLDivElement | null>(null);
  const isOwned = !!selectedLand?.owner;
  const isSale =
    selectedLand &&
    currencyPriceMap["usdc"][selectedLand.size] <
      listingPriceMap[selectedLand.size];
  return (
    <div className="flex flex-col max-w-full max-h-full gap-2 overflow-hidden shrink-0 md:w-72">
      {selectedLand ? (
        <div ref={landRef} className="flex flex-col items-center gap-2">
          <div className="flex items-center justify-between w-full">
            <b className="w-full text-xl">Land {selectedLand.id}</b>

            <CopyButton
              className="p-2"
              hideIcon
              tip={"Copy URL address to clipboard"}
              longString={window.location.origin + `/map/${selectedLand.nft}`}
            >
              <ShareIcon className="h-5" />
            </CopyButton>
          </div>
          <div className="aspect-square max-w-[80%] md:max-w-full">
            <ImagesCarousel images={[landSizeImages[selectedLand.size]]} />
          </div>
          <div className="flex flex-col w-full gap-0">
            <div className="flex items-center w-full gap-2 mt-1">
              <small>LAND SIZE:</small>
              <div
                className={twMerge(
                  "flex h-8 w-8 items-center justify-center rounded border border-contrast py-1",
                  landSizes[selectedLand.size][1]
                )}
              >
                {landSizes[selectedLand.size][0]}
              </div>
              <div className="text-contrast2">
                ({landSizes[selectedLand.size][2]})
              </div>
            </div>
            <div className="flex items-center gap-2 mt-1">
              <small>TOKEN ID:</small>
              <span>{selectedLand.nft}</span>
            </div>
            <div className="flex items-center gap-2 mt-1">
              <small>OWNER:</small>
              <span className="text-contrast2">
                {selectedLand.owner ? (
                  <CopyButton longString={selectedLand.owner}>
                    {shorten(selectedLand.owner)}
                  </CopyButton>
                ) : (
                  "This land has no owner yet!"
                )}
              </span>
            </div>
            {isOwned && selectedLand.tx ? (
              <>
                <div className="flex items-center gap-2 mt-1">
                  <small>TRANSACTION:</small>
                  <span className="text-contrast">
                    <TransactionLink hideLabel tx={selectedLand.tx} />
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="flex items-center gap-2 mt-1">
                  <small>PRICE:</small>
                  <span className={isSale ? "text-green-200" : "text-contrast"}>
                    {usdFormatter.format(
                      currencyPriceMap["usdc"][selectedLand.size] /
                        currencyDecimals["usdc"]
                    )}
                  </span>
                  <span className="line-through text-contrast2">
                    {usdFormatter.format(
                      listingPriceMap[selectedLand.size] /
                        currencyDecimals["usdc"]
                    )}
                  </span>
                </div>
                <div className="flex items-center gap-2 mt-1">
                  <small>PRICE (Mr. Keys):</small>
                  <span className="text-contrast">
                    {currencyPriceMap["mrkey"][selectedLand.size]}
                  </span>
                </div>
              </>
            )}
          </div>

          <BuyButtons
            districtId={districtId}
            mapId={mtbMap.id}
            selectedLand={selectedLand}
            updateMap={updateMap}
            setError={setError}
            setRequestState={setRequestState}
          />
        </div>
      ) : (
        <div className="flex flex-col gap-10 text-lg font-medium text-center text-contrast2">
          <div>Select a land to buy it or to show its details</div>
        </div>
      )}
    </div>
  );
};

export default MtbMapInfo;

import NotificationToast from "components/notifications/Toast";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  notificationsSelectors,
  notificationsActions,
} from "store/slices/notifications";
const notificationsRoot = document.getElementById("notifications-root");
/** [title, description, type, imageUrl] */
export const NotifcationsContainer: React.FC = () => {
  const notifications = useSelector(notificationsSelectors.toasts);
  const dispatch = useDispatch();
  if (!notificationsRoot) return <></>;
  return createPortal(
    <div className="fixed bottom-0 right-0 z-50 flex flex-col justify-end gap-3 p-3 overflow-hidden no-scrollbar">
      {notifications.map((notification, i) => (
        <NotificationToast
          key={i}
          {...{ ...notification }}
          remove={() =>
            dispatch(notificationsActions.removeNotification(notification.id))
          }
        />
      ))}
    </div>,
    notificationsRoot
  );
};

import { HomeIcon, MapIcon } from "@heroicons/react/24/outline";
import { AboutMetable } from "components/others/AboutMetable";
import NavButton, { RouteArray } from "components/navbars/Sidebar/NavButton";
import { twMerge } from "tailwind-merge";
// import { useLocation } from "react-router-dom";

export const pageRoutes: RouteArray[] = [
  [MapIcon, "Metaverse", "/map"],
  // [Squares2X2Icon, "Lms", "/lms"],
  // [BuildingStorefrontIcon, "Marketplace", "/marketplace"],
  // [UserCircleIcon, "Avatar", "/avatar"],
  // [QuestionMarkCircleIcon, "Faq", "/faq"],
];
type Props = { className?: string };
export const Sidebar: React.FC<Props> = ({ className }) => {
  // const location = useLocation();
  // const mapMode = location.pathname.includes("map");

  // const userRoutes: RouteArray[] = [
  //    [BookmarkSquareIcon, "Ownerships", "/ownerships"],
  //    [ChatBubbleLeftRightIcon, "Messages", "/messages"],
  // ];
  return (
    <div
      className={twMerge(
        "flex h-full flex-col justify-between bg-neutral px-4 py-4 shrink-0 w-48",
        className
      )}
    >
      <div className="flex flex-col gap-5">
        {pageRoutes.map((routeArray, i) => {
          return <NavButton routeArray={routeArray} key={i} />;
        })}
      </div>

      <div className="flex flex-col gap-5">
        <div className="h-[1px] w-full bg-gradient-to-r from-secondary to-primary" />
        {/*
        {userRoutes.map((RouteArray, i) => {
          return <NavButton routeArray={RouteArray} key={i} />;
          // return <NavButton routeArray={RouteArray} key={i} mini={mapMode} />;
        })}
         <UserButton />
         <UserButton mini={mapMode} /> 
         */}
        <AboutMetable />
      </div>
    </div>
  );
};

export default Sidebar;

export type FlowError = {
  code?: string;
  description?: string | React.ReactNode;
  callback?:()=>void
};
export const errorMap: Record<string, React.ReactNode> = {
  SIGNATURE_ERROR: "An error occured during the data signature process.",
  GOVERNANCE_AIRDROP_ERROR: "Account not eligible!",
  AUTH_TOKEN_ERROR: "Missing auth token",
  UNKNOWN_ERROR: "We are sorry, an unknown error occured.",
  GENERIC_ERROR: "We are sorry, an unknown error occured.",
  DIFFERENT_ADDRESS_ERROR: "Different address from request",
  GOVERNANCE_AIRDROP_ALREADY_CLAIMED_ERROR:
    "You have already claimed the governance tokens",
  NO_CHAIN: "No active chain",
  NO_ADDRESS: "No user address",
  DECLINED_NETCHANGE: "The network change has been declined.",
  NFT_ALREADY_BOUGHT_ERROR:
    "We are sorry, but this land is no longer available.",
  SIGNATURE_REJECTED: "The signature request has been rejected.",
  APPROVE_REJECTED:"The approve request has been rejected.",
  SIGNATURE_UNAVAILABLE_ERROR: (
    <div className="text-base">
      We've encountered an error while requesting you to sign the permit data.
      <br />
      If you are using the <b>Metamask Mobile</b> application it could be
      because of a known bug in their application that should be patched within
      the next weeks.<br />
      <br /> Please, try with another wallet application (eg.{" "}
      <b>Coinbase Wallet</b>) or a desktop browser with the Metamask extension.
    </div>
  ),
};
export default errorMap;

import { XMarkIcon } from "@heroicons/react/24/solid";
import { LandData } from "apis/fetchMapData";
import { MtbMap } from "components/LeafletMap/LeafletMap";
import { RequestState } from "components/cards/MtbMap/BuyButtons";
import MtbMapInfo from "components/cards/MtbMap/Info";
import PermitApproveModal from "components/cards/MtbMap/PermitApproveModal";
import { useState } from "react";
import { FlowError } from "components/cards/MtbMap/utils/errorMap";

type Props = {
  districtId?: number;
  mtbMap: MtbMap;
  close: () => void;
  selectedLand: LandData | undefined;
  updateMap: () => void;
};
// Mock function to create mtbMaps
export const MtbMapCard: React.FC<Props> = ({
  districtId = 1,
  mtbMap,
  close,
  selectedLand,
  updateMap,
}) => {
  const [requestState, setRequestState] = useState<RequestState>("idle");
  const [error, setError] = useState<FlowError>();

  return (
    <div className="relative z-10 flex flex-col gap-4 p-4 mt-2 mb-6 mr-1 w-fit grow rounded-xl bg-neutral/90 md:flex-row">
      {selectedLand && (
        <div
          onClick={close}
          role="button"
          tabIndex={0}
          className="absolute z-20 flex p-1 border-2 rounded-full -top-2 -right-2 border-neutral bg-primary"
        >
          <XMarkIcon className="w-5 h-5" />
        </div>
      )}
      <MtbMapInfo
        mtbMap={mtbMap}
        selectedLand={selectedLand}
        districtId={districtId}
        updateMap={updateMap}
        setError={setError}
        setRequestState={setRequestState}
      />
      <PermitApproveModal
        selectedLand={selectedLand}
        requestState={requestState}
        updateMap={updateMap}
        close={() => {
          setRequestState("idle");
          setError(undefined);
        }}
        error={error}
      />
    </div>
  );
};

export default MtbMapCard;

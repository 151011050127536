import Unauthorized from "./Unauthorized";
import Maintenance from "./Maintenance";
import NotFound from "./NotFound";
import Loading from "./Loading";
import Home from "./Home";
import Login from "./Login";
import Map from "./Map";

/**  Contains all routable pages, when creating a new page, add it here */
const pages = {
  Unauthorized,
  NotFound,
  Loading,
  Maintenance,
  Login,
  Home,
  Map
};

export default pages;

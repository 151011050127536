import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import BasicButton from "components/buttons/Basic";
import { twMerge } from "tailwind-merge";

const { REACT_APP_STAGE } = process.env;
const currentEnv = REACT_APP_STAGE as keyof typeof polygonScan;
const polygonScan = {
  dev: "https://mumbai.polygonscan.com/tx/",
  prod: "https://polygonscan.com/tx/",
};

export const TransactionLink: React.FC<{ tx: string; hideLabel?: boolean;className?:string }> = ({
  tx,
  hideLabel,
  className
}) => {
  return (
    <>
      {/* <code className="text-contrast2">{tx}</code> */}
      <div className={twMerge("flex items-center justify-center gap-2",className)}>
        {!hideLabel && <span>You can view the transaction details on </span>}
        <a href={polygonScan[currentEnv] + tx} rel="noreferrer" target="_blank">
          <BasicButton className="flex gap-2 py-0">
            Polygon scan <ArrowTopRightOnSquareIcon className="h-4" />
          </BasicButton>
        </a>
      </div>
    </>
  );
};

export default TransactionLink;

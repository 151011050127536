import { decodeTokenId } from "apis/tokenId";
import { Navigate, useParams } from "react-router-dom";

export const NftReroute = () => {
  const { nft } = useParams();
  const { district, map, land } = decodeTokenId(nft || "");
  return (
    <Navigate
      replace
      to={`/map?district=${district}&map=${map}&land=${land}`}
    />
  );
};

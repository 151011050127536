import { Outlet } from "react-router-dom";
import FixedSpaceContainer from "components/containers/FixedSpace";
import VersionWatermark from "components/VersionWatermark";
import DefaultNavbar from "components/navbars/Default";
import DefaultSidebar from "components/navbars/Sidebar";

type Props = {
  asParent?: boolean;
  children?: React.ReactNode;
};
export const MainLayout: React.FC<Props> = ({ asParent, children }) => {
  return (
    <div
      className={
        "relative flex h-screen max-h-screen w-screen flex-col overflow-hidden bg-neutral2 text-contrast"
      }
    >
      <FixedSpaceContainer>
        <div className="flex flex-col w-full h-full">
          <DefaultNavbar />
          <div className="flex w-full h-full overflow-y-hidden">
            <DefaultSidebar className="hidden md:flex" />
            <div className="flex flex-col h-full overflow-x-hidden grow">
              <div className="h-full overflow-y-auto subtle-scrollbar">
                {asParent ? children : <Outlet />}
              </div>
            </div>
          </div>
        </div>
      </FixedSpaceContainer>
      <VersionWatermark />
    </div>
  );
};
export default MainLayout;

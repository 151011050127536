import Hero from "./Hero";
import News from "./News";
import Section from "./Section";
import Footer from "./Footer";
import { twMerge } from "tailwind-merge";
export const Home: React.FC = () => {
  return (
    <div className="flex flex-col min-h-full bg-gradient">
      <Hero />
      <div
        className={twMerge(
          "mx-auto flex max-w-7xl flex-col gap-12 overflow-hidden px-8 pt-10"
        )}
      >
        <News />
        <Section />
        <Footer />
      </div>
    </div>
  );
};

export default Home;

import DivButton from "components/buttons/Div";
import { ReactComponent as Facebook } from "assets/facebook.svg";
import { ReactComponent as Linkedin } from "assets/linkedin.svg";
import { ReactComponent as Twitter } from "assets/twitter.svg";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { decodeTokenId } from "apis/tokenId";
import { landSizeImages } from "components/cards/MtbMap/Info";

type Props = {
  landNft: string;
};
export const ShareButtons: React.FC<Props> = ({ landNft }) => {
  const { size,district,map,land } = decodeTokenId(landNft);
  const origin = window.location.origin
  // const landImage = origin + landSizeImages[size];
  const landUrl=`${origin}/map/${landNft}`
  const content = `I am thrilled to share with all of you my new land! District: #${district}, Map: #${map}, Land: #${land} in the metable-metaverse!`;
  const tags=["metable","metaverse","LandSale",]
  const twitterHandle="@metable_in"
  return (
    <div className="mt-4">
      <div className="hidden w-full mb-1 text-lg font-medium text-center sm:block">
        Share your land on:
      </div>
      <div className="flex flex-col justify-center w-full gap-2 sm:flex-row">
        <TwitterShareButton
          title={content}
          related={[twitterHandle]}
          url={landUrl}
          hashtags={tags}
        >
          <DivButton className="w-full bg-[#1DA1F2] text-white sm:w-36">
            <b className="mr-2 sm:hidden"> Share on</b>
            Twitter
            <Twitter className="fill-white" />
          </DivButton>
        </TwitterShareButton>
        <FacebookShareButton
          quote={content}
          url={landUrl}
          hashtag={"#"+tags[0]}
        >
          <DivButton className="w-full bg-gradient-to-b from-[#17a9fc] to-[#0064e0] text-white sm:w-36">
            <b className="mr-2 sm:hidden"> Share on</b>
            Facebook
            <Facebook className="fill-white" />
          </DivButton>
        </FacebookShareButton>

        <LinkedinShareButton
          title={"My metable land"}
          url={landUrl}
          summary={content}
          source={landUrl}
        >
          <DivButton className="w-full bg-[#0077b5] text-white sm:w-36">
            <b className="mr-2 sm:hidden"> Share on</b>
            LinkedIn
            {/* <LinkedinShareButton/> */}
            <Linkedin className="fill-white" />
          </DivButton>
        </LinkedinShareButton>
      </div>
    </div>
  );
};

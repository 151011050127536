import Markdown from "components/Markdown";
import TransparentButton from "components/buttons/Transparent";
import BasicModal from "components/modals/Basic";
import LoadingSpinner from "components/others/LoadingSpinner";
import { useEffect, useState } from "react";

const preview = (
  <div className=" w-72 shrink-0 rounded-xl">
    <img
      alt="News"
      src="images/previews/release.png"
      className="object-contain w-full h-full"
    />
  </div>
);
export const Section: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [markdownContent, setMarkdownContent] = useState<string>();
  useEffect(() => {
    if (!isOpen || markdownContent) return;
    setIsLoading(true);
    fetch("articles/release.MD")
      .then((res) => res.text())
      .then((res) => {
        setMarkdownContent(res);
        setIsLoading(false);
      });
  }, [isOpen, markdownContent]);
  return (
    <div className="flex flex-col gap-8">
      <div className="h-[1px] w-full bg-gradient-to-r from-primary to-secondary" />
      <div className="flex flex-col justify-between gap-4 py-4 sm:flex-row sm:gap-24">
        <div className="flex flex-col justify-center gap-8 ">
          <div className="text-3xl font-bold">Next Metaverse Release</div>
          <div className="flex justify-center w-full sm:hidden">{preview}</div>
          <div>
            We are thrilled to share an exhilarating update on the progress of
            our 3D infrastructure and blockchain framework.
          </div>
          <TransparentButton
            className="px-8 border-2 border-contrast text-contrast"
            onClick={() => setIsOpen(true)}
          >
            Read more
          </TransparentButton>

          <BasicModal
            open={isOpen}
            close={() => setIsOpen(false)}
            title={"Metaverse Release"}
            className="w-[900px]"
            bodyClassName="md:p-10 md:px-14"
            closableOnOverlayClick
          >
            <div className="text-contrast">
              <div className="mb-8 text-2xl">Next Metaverse Release</div>

              {isLoading || !markdownContent ? (
                <div className="flex items-center justify-center w-full">
                  <LoadingSpinner className="w-10 h-10" />
                </div>
              ) : (
                <div className="font-light">
                  <Markdown children={markdownContent} />
                </div>
              )}
            </div>
          </BasicModal>
        </div>
        <div className="hidden sm:block">{preview}</div>
      </div>
    </div>
  );
};

export default Section;

import endpoint from "apis/endpoints";

const mapData: LandData[][][] = [];
export const deleteMapDataCache = (districtId?: number, mapId?: number) => {
  if (districtId) {
    if (mapId) {
      delete mapData[districtId][mapId];
    }
    delete mapData[districtId];
  }
  mapData.length = 0;
};
const cacheMapData = (
  districtId: number,
  mapId: number,
  landsData: LandData[]
) => {
  if (!mapData[districtId]) {
    mapData[districtId] = [];
  }
  mapData[districtId][mapId] = landsData;
};
export const fetchMapData = async (districtId: number, mapId: number) => {
  if (mapData[districtId]?.[mapId]) {
    return mapData[districtId]?.[mapId];
  }
  const res = await fetch(`${endpoint}land/get/${districtId}/${mapId}`).then(
    (body) => body.json()
  );
  if (Array.isArray(res)) {
    const normalizedRes = res.map(normalizeLand);
    cacheMapData(districtId, mapId, normalizedRes);
    return normalizedRes;
  }
  return [];
};

export default fetchMapData;

export type LandData = {
  id: number;
  district_id: number;
  map_id: number;
  size: 0 | 1 | 2 | 3;
  cart_expire: number;
  owner: `0x${string}` | undefined;
  nft: string;
  status: string;
  tx?: string;
};

const normalizeLand = (fetchedLand: any) => {
  const land: LandData = {
    id: 0,
    district_id: 0,
    map_id: 0,
    size: 0,
    nft: "",
    cart_expire: 0,
    owner: undefined,
    status: "reserved",
    tx:undefined
  };
  land["id"] = Number(fetchedLand.land);
  land["district_id"] = Number(fetchedLand.district);
  land["map_id"] = Number(fetchedLand.map);
  land["nft"] = fetchedLand.nft;
  land["cart_expire"] = fetchedLand.chart_expire;
  land["size"] = Number(fetchedLand.type) as 0 | 1 | 2 | 3;
  land["status"] = fetchedLand.status;
  land["owner"] = fetchedLand.address;
  try {
    land["tx"] = JSON.parse(fetchedLand.data).tx;
  } catch {
    land["tx"] = undefined;
  }

  return land;
};

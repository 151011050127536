import { LandSize } from "pages/Map/Map";

export function makeTokenId(
  districtId: number,
  mapId: number,
  landId: number,
  landSize: number
) {
  return (
    BigInt(landId) |
    (BigInt(mapId) << BigInt(16)) |
    (BigInt(districtId) << BigInt(32)) |
    (BigInt(landSize) << BigInt(40))
  ).toString();
}
export function decodeTokenId(tokenId: string) {
  const bigTokenId = BigInt(tokenId);
  const size = Number(((bigTokenId >> BigInt(40)) & BigInt("0xFF")).toString()) as LandSize;
  const district = Number(
    ((bigTokenId >> BigInt(32)) & BigInt("0xFF")).toString()
  );
  const map = Number(
    ((bigTokenId >> BigInt(16)) & BigInt("0xFFFF")).toString()
  );
  const land = Number((bigTokenId & BigInt("0xFFFF")).toString());

  return { size, district, map, land };
}

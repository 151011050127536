import { HomeIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
// import { Tooltip } from "components/popovers/Tooltip";

export type RouteArray = [typeof HomeIcon, string, string];
type Props = {
  routeArray: RouteArray;
  className?: string;
  mini?: boolean;
};
export const NavButton: React.FC<Props> = ({
  routeArray: [Icon, alias, route],
  mini,
  className,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = location.pathname === route;

  //TODO: This is temporary, remove this as soon as land sale starts
  // if (alias === "Map") {
  //   return (
  //     <Tooltip placement="bottom-end" tip="The map will be available at the start of land sales!">
  //       <button
  //         disabled
  //         className={twMerge(
  //           "flex h-6 select-none items-center gap-3 font-light transition-all duration-200 text-contrast2",
  //           className
  //         )}
  //         onClick={() => navigate(route)}
  //       >
  //         <Icon className={twMerge("h-6")} />
  //         {!mini && <span>{alias}</span>}
  //       </button>
  //     </Tooltip>
  //   );
  // }
  return (
    <button
      className={twMerge(
        "flex h-6 select-none items-center gap-3 font-light transition-all duration-200",
        isActive
          ? "bg-gradient-to-r from-secondary to-primary bg-clip-text font-semibold text-transparent"
          : "text-contrast hover:text-contrast/75",
        className
      )}
      onClick={() => navigate(route)}
    >
      <Icon className={twMerge("h-6", isActive && "text-primary")} />
      {!mini && <span>{alias}</span>}
    </button>
  );
};

export default NavButton;

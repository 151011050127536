import { LatLngBounds } from "leaflet";

export const mapsBounds = new LatLngBounds([
  [-6, -6],
  [30, 30],
]);
const xB = 9.8163265306122449;
const yB = 9.9183673469387755;

export const landsBounds = new LatLngBounds([-xB, -yB], [14 + xB, 14 + yB]);
const { lat, lng } = landsBounds.getCenter();
export const logoBounds = new LatLngBounds(
  [lat + 1.8, lng - 0.5],
  [lat + 0.8, lng + 0.5]
);

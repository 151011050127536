import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LandData } from "apis/fetchMapData";
import { RootState } from "store/reducers";

const initialState: { mapData: LandData[][][] } = {
  mapData: [],
};
export const mapDataSlice = createSlice({
  name: "mapData",
  initialState,
  reducers: {
    getMapData: (
      state,
      { payload }: PayloadAction<{ districtId: number; mapId: number }>
    ) => state,
    setMapData: (
      state,
      {
        payload,
      }: PayloadAction<{
        districtId: number;
        mapId: number;
        landData: LandData[];
      }>
    ) => {
      state.mapData[payload.districtId][payload.mapId] = payload.landData;
      return state;
    },
  },
});

export const mapDataSelectors = {
  mapData: (state: RootState) => state.mapData,
};

const { actions, reducer } = mapDataSlice;
export { reducer, actions as mapDataActions };

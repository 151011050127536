import { LandData } from "apis/fetchMapData";
import { RequestState } from "components/cards/MtbMap/BuyButtons";
import { landSizeImages } from "components/cards/MtbMap/Info";
import BasicModal from "components/modals/Basic";
import ImageDiv from "components/others/ImageDiv";
import LoadingSpinner from "components/others/LoadingSpinner";
import TransactionLink from "components/TransactionLink";
import errorMap, { FlowError } from "components/cards/MtbMap/utils/errorMap";
import BasicButton from "components/buttons/Basic";
import { ShareButtons } from "components/cards/MtbMap/ShareButtons";
import { useEffect } from "react";

type Props = {
  close: () => void;
  requestState: RequestState;
  error: FlowError | undefined;
  selectedLand: LandData | undefined;
  updateMap: () => void;
};
export const PermitApproveModal: React.FC<Props> = ({
  requestState,
  close,
  error,
  selectedLand,
  updateMap,
}) => {
  useEffect(() => {
    if (requestState === "error") {
      updateMap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestState]);

  if (!selectedLand) return <></>;
  return (
    <BasicModal title="Land buy" open={requestState !== "idle"} close={close}>
      <div className="text-lg font-medium text-contrast">
        {(() => {
          switch (requestState) {
            case "error":
              const code = error?.code || "UNKNOWN_ERROR";
              const errObj = {
                code,
                description:
                  errorMap[code] ||
                  error?.description ||
                  errorMap["UNKNOWN_ERROR"],
              };
              return (
                <div className="flex flex-col w-full font-normal text-center">
                  <div className="p-2 font-light">{errObj.description}</div>
                  <div className="flex flex-wrap items-center justify-center gap-1 my-3">
                    <small className="font-light">CODE:</small>
                    <code className=" text-danger">{errObj.code}</code>
                  </div>
                  {[
                    "SIGNATURE_REJECTED",
                    "SIGNATURE_UNAVAILABLE_ERROR",
                    "SIGNATURE_ERROR",
                  ].includes(errObj.code) &&
                    error?.callback && (
                      <div className="flex flex-col p-2 mt-2 border-t border-white">
                        <div className="text-lg text-left grow">
                          If the signature request is not working, you can also
                          try the <b>ERC20 Approve</b> method.
                        </div>{" "}
                        <div className="flex justify-end">
                          <BasicButton
                            className=" bg-gradient-to-b from-primary to-secondary"
                            onClick={error.callback}
                          >
                            Buy with Approve
                          </BasicButton>
                        </div>
                      </div>
                    )}
                </div>
              );
            case "sign":
              return (
                <div className="font-normal">Waiting for user signature...</div>
              );
            case "loading":
              return (
                <div className="flex flex-col items-center gap-2 font-normal text-contrast">
                  <div>
                    The transaction is currently being processed, please wait...
                  </div>
                  <LoadingSpinner className="h-5 text-contrast2" />
                </div>
              );
            case "success":
              if (selectedLand.tx) {
                window.localStorage.setItem(selectedLand.nft, selectedLand.tx);
                console.log("Saving ", selectedLand.tx);
              }
              const localStorageTransaction = window.localStorage.getItem(
                selectedLand.nft
              );
              console.log("LocalStorageTx: ", localStorageTransaction);
              return (
                <div className="flex flex-col items-center gap-3 mx-6 mb-3">
                  <div className="w-full">
                    <div className="w-full text-center">Congratulations!</div>{" "}
                    <div className="text-base font-normal text-center">
                      Your order has been processed. Once the transaction is
                      validated, you will find the NFT in your wallet.
                    </div>
                  </div>
                  <div className="flex">LAND ID: {selectedLand.nft}</div>
                  <div className="flex justify-center w-full">
                    <ImageDiv
                      src={landSizeImages[selectedLand.size]}
                      className="m-auto w-80 max-w-[80%] rounded-3xl"
                    />
                  </div>
                  {selectedLand.tx ? (
                    <TransactionLink
                      className="flex-col"
                      tx={selectedLand.tx}
                    />
                  ) : (
                    <div className="flex flex-col items-center w-full gap-2">
                      <div className="mt-1 font-light text-red-400">
                        Something went wrong with the transaction
                      </div>
                      {localStorageTransaction && (
                        <TransactionLink
                          className="flex-col"
                          tx={localStorageTransaction}
                        />
                      )}
                    </div>
                  )}
                  <div className="my-1 h-[1px] w-full bg-gradient-to-r from-secondary to-primary" />
                  <div className="text-base font-light">
                    Thank you for purchasing your land and officially becoming a{" "}
                    citizen of the <b>Founding District</b>.<br />
                    <br />
                    Share your purchase on Twitter, LinkedIn, and Instagram
                    using <b>#Metable</b> <b>#Metaverse</b> <b>#education</b>,
                    and don't forget to tag our official profile.
                    <br />
                    <br />{" "}
                    <div className="w-full font-normal text-center">
                      Our journey together has just begun, and exciting things
                      await us in the coming months!
                    </div>
                    <ShareButtons landNft={selectedLand.nft} />
                  </div>
                </div>
              );
            case "tx":
              return (
                <div className="flex flex-col items-center gap-2 font-normal text-contrast">
                  <div>Waiting for transaction hash...</div>
                  <LoadingSpinner className="h-5 text-contrast2" />
                </div>
              );
            case "approve":
              return (
                <div className="flex flex-col items-center gap-2 font-normal text-contrast">
                  <div>
                    Please confirm the approval for the requested amount
                  </div>
                  <LoadingSpinner className="h-5 text-contrast2" />
                </div>
              );
          }
        })()}
      </div>
    </BasicModal>
  );
};
export default PermitApproveModal;

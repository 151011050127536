import LoadingSpinner from "components/others/LoadingSpinner";
import { useEffect, useState } from "react";

type Props = {
  update: () => void;
  interval?: 9;
};
const POLL_INTERVAL = 15;
export const RefreshCountdown: React.FC<Props> = ({
  update,
  interval = POLL_INTERVAL,
}) => {
  const [timeLeft, setTimeLeft] = useState<number>(interval);
  useEffect(() => {
    if (timeLeft <= 0) {
      setTimeout(() => setTimeLeft(interval), 2000);
      update();
      return;
    }
    setTimeout(() => setTimeLeft((prev) => prev - 1), 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return (
    <div className="absolute bottom-0 z-10 pt-2 text-lg font-light right-4 text-contrast sm:top-2">
      <div
        className="flex justify-between w-full gap-3"
        style={{ textShadow: "0 0 8px #000000" }}
      >
        {timeLeft <= 0 ? (
          <div className="flex items-center gap-2">
            updating <LoadingSpinner className="h-4 fill-contrast" />
          </div>
        ) : (
          `refreshing in ${timeLeft}...`
        )}
      </div>
    </div>
  );
};
export default RefreshCountdown;

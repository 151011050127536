import { currentEnv } from "env";
const apiKey = {
  dev: "55fddbbf-4a15-4abf-9a9a-48f0b15f9a8a",
  prod: "2144fa93-23b7-4dca-a1c6-8a4e39cc791b",
};
const baseUrl={
  dev:"https://global-stg.transak.com/",
  prod:"https://global.transak.com/"
}
export const getTransakUrl = (userAddress: `0x${string}`, amount: number) => {
  const countryCode = "CH";
  const fiatCurrency = "EUR";
  const cryptoCurrency = "USDC";
  const params = `?apiKey=${apiKey[currentEnv]}&environment=${
    currentEnv === "prod" ? "PRODUCTION" : "STAGING"
  }&cryptoCurrencyCode=${cryptoCurrency}&networks=polygon&walletAddress=${userAddress}&fiatCurrency=${fiatCurrency}&countryCode=${countryCode}&disableWalletAddressForm=true&cryptoAmount=${amount}&defaultCryptoAmount=${amount}`;

  return baseUrl[currentEnv] + params;
};

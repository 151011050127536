import { Logo } from "components/others/Logo";
import walletConnectLogo from "assets/wallet-connect.svg";
import { useWeb3Modal } from "@web3modal/react";
import BasicButton from "components/buttons/Basic";

export const Login: React.FC = () => {
  const { open } = useWeb3Modal();
  return (
    <>
      <div className="w-full p-4">
        <Logo />
      </div>
        <div className="w-full mt-10 text-xl text-center">Welcome!</div>
      <div className="flex justify-center pt-10 text-2xl sm:text-base">
        <div className="grid gap-5 font-bold sm:grid-cols-[300px_300px] sm:grid-rows-[300px]">
          <BasicButton
            onClick={() => {
              open();
            }}
            className="w-full gap-3 bg-transparent bg-gradient-to-br from-secondary/90 to-primary/90 text-contrast"
          >
            Connect via Web3
            <img
              alt="WalletConnect logo"
              src={walletConnectLogo}
              className="h-6"
            />
          </BasicButton>
          <BasicButton
            disabled
            className="w-full gap-3 bg-white/95 disabled:opacity-80 text-neutral"
          >
            Guest <small className="text-neutral2">(not available yet)</small>
          </BasicButton>
        </div>
      </div>
    </>
  );
};

export default Login;

const { REACT_APP_VERSION } = process.env;
export const VersionWatermark: React.FC = () => {
  if (REACT_APP_VERSION === "$npm_package_version") return <></>;
  if (!REACT_APP_VERSION) return <></>;

  return (
    <div className="fixed select-none bottom-2 right-2 text-contrast">
      Metable v.{REACT_APP_VERSION}
    </div>
  );
};
export default VersionWatermark;

import { Bars3Icon } from "@heroicons/react/24/solid";
import BasicButton from "components/buttons/Basic";
import ImportTokenButton from "components/buttons/ImportToken";
import LoginButton from "components/buttons/Login";
import { MobileMenu } from "components/navbars/MobileMenu";
import { Logo } from "components/others/Logo";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export const DefaultNavbar: React.FC = () => {
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  location.pathname.includes("/map");
  const navigate = useNavigate()
  return (
    <div
      className={twMerge(
        "z-50 flex h-16 w-full items-center justify-between gap-3",
        "bg-neutral p-4 shadow-xl shadow-primary/10",
        "relative"
      )}
    >
      <div role="button" className="flex items-center" onClick={()=>navigate("/home")}>
        <Logo />
      </div>
      <div className="flex items-center gap-4">
        <div className="md:hidden">
          <BasicButton
            className="bg-transparent"
            onClick={() => setOpenMenu((prev) => !prev)}
          >
            <Bars3Icon className="h-6" />
          </BasicButton>
        </div>
        <div className="hidden md:flex">
          <ImportTokenButton />
        </div>
        <LoginButton />
      </div>
      <MobileMenu open={openMenu} close={() => setOpenMenu(false)} />
    </div>
  );
};

export default DefaultNavbar;

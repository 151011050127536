import { Currencies } from "components/cards/MtbMap/BuyButtons";


export const allowListContract: {
  dev: `0x${string}`;
  prod: `0x${string}`;
} = {
  
  dev: "0x1432053A025bA8e39D139d6A20f8d1B36e35411e",
  prod: "0x036498B552bae34666f1082c48653Aed87b87867",
};

export const landSaleContract: {
  dev: `0x${string}`;
  prod: `0x${string}`;
} = {
  dev: "0x8c24e74f1c37c5a17c3a180e323bd9bbb502f757",
  prod: "0x798a43527D56baFECc282A1f3FD9581A44F4458D",
};

export const landsContract: {
  dev: `0x${string}`;
  prod: `0x${string}`;
} = {
  dev: "0xbF5F2D112258A576d17cb8cF41e809A2A2e2F3A8",
  prod: "0x41b870Cb9b7eC5E8eDdC8111e5f4BC74947c81Df",
};

export const landOwnerAddress:{
  dev: `0x${string}`;
  prod: `0x${string}`;
} = {
  dev: "0x645261d8eFd9Ba34f115FAAe1fd43E6bE4872CEe",
  prod: "0xe94E297FFDa2dD0dbB2E1C86c005413987B88115",
};

export const currencyContract: {
  dev: Record<string, `0x${string}`>;
  prod: Record<string, `0x${string}`>;
} = {
  dev: {
    mrkey: "0x2928e7a013626e492bA3A2261FD67b78356231B8",
    usdc: "0x0FA8781a83E46826621b3BC094Ea2A0212e71B23",
    usdt: "0xb275373224BAdC2DCC95672Cd786F1454f7bDe05",
    busd: "0x663b40be59a1884108DdC15c93Fd2d4e076331C2",
  },
  prod: {
    mrkey: "0x73a26175c4481f225A0269EA102C01244f571951",
    usdc: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    usdt: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    busd: "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39",
  },
};

export const currencyDecimals = {
  usdt: 10 ** 6,
  usdc: 10 ** 6,
  busd: 10 ** 18,
  mrkey: 10 ** 0,
} as const;



export const listingPriceMap=[
  450 * currencyDecimals["usdt"],
  1_800 * currencyDecimals["usdt"],
  11_250 * currencyDecimals["usdt"],
  45_000 * currencyDecimals["usdt"],
]


export const currencyPriceMap: Record<
  Currencies,
  [number, number, number, number]
> = {
  /**
   * [currency]: [land S, land M, land L, land XL]
   */
  mrkey: [
    1 * currencyDecimals["mrkey"],
    4 * currencyDecimals["mrkey"],
    25 * currencyDecimals["mrkey"],
    100 * currencyDecimals["mrkey"],
  ],
  usdt: [
    293 * currencyDecimals["usdt"],
    1112 * currencyDecimals["usdt"],
    6581 * currencyDecimals["usdt"],
    45_000 * currencyDecimals["usdt"],
  ],
  usdc: [
    293 * currencyDecimals["usdc"],
    1112 * currencyDecimals["usdc"],
    6581 * currencyDecimals["usdc"],
    45_000 * currencyDecimals["usdc"],
  ],
  busd: [
    293 * currencyDecimals["busd"],
    1112 * currencyDecimals["busd"],
    6581 * currencyDecimals["busd"],
    45_000 * currencyDecimals["busd"],
  ],
};
import endpoint from "apis/endpoints";

export const fetchDistrictMapData = async (districtId: number) => {
  const res = await fetch(`${endpoint}map/${districtId}`).then((body) =>
    body.json()
  );
  if (Array.isArray(res.maps)) {
    const normalizedRes = (res.maps as any[])
      .map(normalizeMap)
      .filter((map) => !!map.status);
    return normalizedRes;
  }
  return [];
};

export default fetchDistrictMapData;

export type DistrictMapData = {
  map: number;
  district: number;
  /** "soldout" */
  status: string;
};

const normalizeMap = (fetchedMap: any) => {
  const map: DistrictMapData = {
    map: 0,
    district: 0,
    status: "",
  };
  map["map"] = Number(fetchedMap.map);
  map["district"] = Number(fetchedMap.district);
  map["status"] = fetchedMap.status;
  return map;
};

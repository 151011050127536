import FixedSpaceContainer from "components/containers/FixedSpace";
import { ReactComponent as LogoIcon } from "assets/logo-icon.svg";
import TransparentButton from "components/buttons/Transparent";
import discordLogo from "assets/images/discord.png";

export const Maintenance: React.FC = () => {
  return (
    <div
      className={
        "to relative flex h-screen max-h-screen w-screen flex-col overflow-hidden bg-neutral bg-gradient-to-br from-neutral2 text-contrast"
      }
    >
      <FixedSpaceContainer>
        <div className="flex flex-col items-center justify-center gap-4 p-6 text-2xl font-light text-center select-none h-2/3 text-contrast">
          <LogoIcon className="w-40 h-40" />
          {/* <div className="text-xl text-contrast/50">
            Metable is undergoing maintenance.
          </div> */}
          <div>We are working on the next step of the Land Sale,</div>
          <div className="text-3xl font-normal">stay tuned.</div>
        </div>
        <a
          href="https://discord.gg/metable-701457840455549029"
          target="_blank"
          rel="noreferrer"
          className="absolute text-xl bottom-5"
        >
          <TransparentButton className="gap-2 px-6 py-2 transition-all bg-primary text-contrast">
            <img src={discordLogo} alt="discord logo" className="h-5" />
            Join our discord
          </TransparentButton>
        </a>
      </FixedSpaceContainer>
    </div>
  );
};
export default Maintenance;

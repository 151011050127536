import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import { useWeb3Modal } from "@web3modal/react";
import { Tooltip } from "components/popovers/Tooltip";
import { useAccount, useDisconnect } from "wagmi";
import walletConnectLogo from "assets/wallet-connect.svg";
type Props = {};
export const LoginButton: React.FC<Props> = () => {
  const { isConnected, address } = useAccount();
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();

  const formattedAddress = address
    ? address.slice(0, 4) +
      "..." +
      address.slice(address.length - 4, address.length)
    : "";

  return (
    <Tooltip tip={isConnected ? "Disconnect" : "Connect to your web3 Wallet"}>
      <div
        className="flex items-center gap-2 rounded-lg border border-contrast px-2 text-contrast shadow-primary hover:bg-primary hover:shadow-[0_0_4px_1px]"
        role="button"
        onClick={() => {
          if (isConnected) return disconnect();
          open();
        }}
      >
        {isConnected ? (
          <>
            <div className="h-2 w-2 rounded-full bg-primary shadow-[0_0_4px_1px] shadow-primary" />
            {formattedAddress}
            <ArrowRightOnRectangleIcon className="h-4" />
          </>
        ) : (
          <>
            Connect
            <img
              alt="WalletConnect logo"
              src={walletConnectLogo}
              className="h-3"
            />
          </>
        )}
      </div>
    </Tooltip>
  );
};

export default LoginButton;

import { useEffect, useState } from "react";
const CLICKS_TO_UNLOCK = 5;
export const useMaintenance = (isMaintenance?: boolean) => {
  const [, setCounter] = useState(0);
  const [showMaintenanceScreen, setShowMaintenanceScreen] = useState(
    isMaintenance || false
  );
  const updateCounter = () =>
    setCounter((prev) => {
      const newVal = prev + 1;
      if (newVal >= CLICKS_TO_UNLOCK) {
        setShowMaintenanceScreen(false);
        return prev;
      }
      console.log(CLICKS_TO_UNLOCK - newVal + " remaining...");
      return newVal;
    });
  useEffect(() => {
    isMaintenance && window.addEventListener("click", updateCounter);
  }, [isMaintenance]);
  return showMaintenanceScreen;
};

import { buyLand, pollLandTransaction } from "apis/buyLand";
import { decodeTokenId } from "apis/tokenId";
import { RequestState, Currencies } from "components/cards/MtbMap/BuyButtons";
import {
  currencyContract,
  landSaleContract,
  currencyPriceMap,
  currencyDecimals,
  // landOwnerAddress,
} from "components/cards/MtbMap/utils/constants";
import { getAllowance } from "components/cards/MtbMap/utils/getAllowance";
import requestApprove from "components/cards/MtbMap/utils/requestApprove";
import requestPermit from "components/cards/MtbMap/utils/requestPermit";
import contractMap from "config/contractMap";
import { currentEnv } from "env";
import { LandSize } from "pages/Map/Map";
import { FlowError } from "components/cards/MtbMap/utils/errorMap";
import { Chain } from "wagmi";
import { switchNetwork } from "wagmi/actions";

export const permitApproveRequest = async (
  address: `0x${string}`,
  currency: Currencies,
  setRequestState: (state: RequestState) => void,
  setLoading: (state: Currencies | undefined) => void,
  setError: (error: FlowError | undefined) => void,
  currentChain: Chain | undefined,
  tokenId: string,
  updateMap: () => void,
  forceApprove?: boolean
) => {
  setRequestState("loading");
  const defaultChain = contractMap[currentEnv].defaultChain;
  const { size } = decodeTokenId(tokenId);
  if (!currentChain) {
    return setError({ code: "NO_CHAIN" });
  }
  if (!address) {
    return setError({ code: "NO_ADDRESS" });
  }
  if (currentChain.id !== defaultChain.id) {
    const res = await switchNetwork({ chainId: defaultChain.id });
    if (res.id !== defaultChain.id) {
      return setError({ code: "DECLINED_NETCHANGE" });
    }
  }
  try {
    let permitData: Awaited<ReturnType<typeof permit>> | undefined = undefined;

    if (currency === "busd" || currency === "usdt" || forceApprove) {
      const res = await approve(setRequestState, currency, size, address);
      if (res?.code) {
        return setError(res);
      }
    } else {
      permitData = await permit(
        setRequestState,
        currency,
        size,
        address,
        currentChain
      );
    }

    if (permitData?.code) {
      const tryApprove = await new Promise((resolve) => {
        setError({ ...permitData, callback: () => resolve(true) });
      });
      tryApprove &&
        permitApproveRequest(
          address,
          currency,
          setRequestState,
          setLoading,
          setError,
          currentChain,
          tokenId,
          updateMap,
          true
        );
      return;
    }
    setRequestState("loading");
    const res = await buyLand(tokenId, currency, address, permitData).then(
      (resp) => resp.json()
    );
    if (res.code) {
      return setError(res);
    }

    const pollRes = await pollLandTransaction(tokenId);
    if (pollRes.status !== "completed") {
      return setError(pollRes.status);
    }
    setRequestState("success");
    updateMap();
  } catch (e) {
    setRequestState("error");
    throw e;
  } finally {
    setLoading(undefined);
  }
};

const permit = async (
  setRequestState: (state: RequestState) => void,
  currency: "mrkey" | "usdc",
  size: LandSize,
  address: `0x${string}`,
  currentChain: Chain
) => {
  setRequestState("sign");
  const permitData = await requestPermit({
    currency,
    tokenAddress: currencyContract[currentEnv][currency],
    ownerAddress: address,
    spenderAddress: landSaleContract[currentEnv],
    value: currencyPriceMap[currency][size],
    chain: currentChain,
  });
  return permitData;
};

const approve = async (
  setRequestState: (state: RequestState) => void,
  currency: Currencies,
  size: LandSize,
  address: `0x${string}`
) => {
  setRequestState("approve");
  const args = {
    tokenAddress: currencyContract[currentEnv][currency],
    spenderAddress: landSaleContract[currentEnv],
    value: currencyPriceMap[currency][size],
  };
  try{
    await requestApprove(args);
  }catch{
    return {code:"APPROVE_REJECTED"}
  }
  setRequestState("tx");
  // Check if allowed amounts is correct
  const allowance = await getAllowance(
    args.tokenAddress,
    address,
    args.spenderAddress
  );
  console.log({ allowance, required: currencyPriceMap[currency][size] });
  console.log(currencyPriceMap[currency][size]);
  if (allowance < currencyPriceMap[currency][size]) {
    return {
      code: "INSUFFICIENT_ALLOWANCE",
      description: (
        <div className="flex flex-col items-center">
          The provided allowance doesn't cover for the Land price. <br />
          <div className="text-left w-fit">
            Your approved amount:
            <b className="text-danger">
              {" "}
              {allowance / currencyDecimals[currency]}
            </b>{" "}
            {currency.toUpperCase()}
            <br /> Required amount:{" "}
            <b>
              {" "}
              {currencyPriceMap[currency][size] /
                currencyDecimals[currency]}{" "}
            </b>
            {currency.toUpperCase()}
          </div>
          <div className="mt-2">
            Please, try again with the corrected amount.
          </div>
        </div>
      ),
    };
  }
  return;
};

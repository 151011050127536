import Markdown from "components/Markdown";
import GradientCard from "components/cards/Gradient";
import BasicModal from "components/modals/Basic";
import LoadingSpinner from "components/others/LoadingSpinner";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  date?: Date;
  type?: string;
  title?: string;
  mini?: boolean;
  id: number;
};
export const NewsCard: React.FC<Props> = ({
  date = new Date(),
  type = "community",
  title = "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  mini,
  id,
}) => {
  const image = `images/previews/${id}.png`;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div
        className={twMerge(
          "group flex w-full flex-col items-stretch gap-2 text-contrast hover:text-fuchsia-200",
          mini && "flex-row"
        )}
        onClick={() => setIsOpen(true)}
        role="button"
        tabIndex={0}
      >
        <GradientCard
          className={twMerge("h-40", mini && "h-28 w-28 shrink-0")}
          src={image}
        />

        <div className="flex flex-col gap-1">
          <div className="flex w-full gap-2 text-contrast">
            <small className="whitespace-nowrap">
              {date.toLocaleString("default", { month: "short" })}{" "}
              {date.getDate()}
            </small>
            <small className="uppercase text-contrast2">{type}</small>
          </div>
          <div
            className={twMerge(
              "flex h-24 w-full min-w-[100px] overflow-ellipsis text-lg",
              mini && "text-base font-light"
            )}
          >
            {title}
          </div>
        </div>
      </div>
      <NewsModal
        isOpen={isOpen}
        id={id}
        title={title}
        close={() => setIsOpen(false)}
      />
    </>
  );
};

type ModalProps = {
  /** Article id */
  id: number;
  title: string;
  isOpen: boolean;
  close: () => void;
};
export const NewsModal: React.FC<ModalProps> = ({
  isOpen,
  close,
  id,
  title,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [markdownContent, setMarkdownContent] = useState<string>();
  const article = `articles/article${id}.MD`;
  useEffect(() => {
    if (!isOpen || markdownContent) return;
    setIsLoading(true);
    fetch(article)
      .then((res) => res.text())
      .then((res) => {
        setMarkdownContent(res);
        setIsLoading(false);
      });
  }, [id, isOpen, markdownContent, article]);

  return (
    <BasicModal
      open={isOpen}
      close={close}
      title={"News - " + title}
      className="w-[900px]"
      bodyClassName="md:p-10 md:px-14"
      closableOnOverlayClick
    >
      <div className="text-contrast">
        <div className="mb-8 text-2xl">{title}</div>
        {isLoading || !markdownContent ? (
          <div className="flex items-center justify-center w-full">
            <LoadingSpinner className="w-10 h-10" />
          </div>
        ) : (
          <div className="font-light">
            <Markdown children={markdownContent} />
          </div>
        )}
      </div>
    </BasicModal>
  );
};
export default NewsCard;
